export default {
	common:{
		approve: '授权',
		needApprove: '请在钱包中确认授权',
		confirm: '确认',
		needConfirm: '等待确认',
		longOrder: 'Long',
		shortOrder: 'Short',
		txConfirmError: '交易失败',
		contract: '合约地址',
		totalSupply: '总供应量',
		tokenSupply: '代币供应量',
		unrealisedProfit: '未实现盈利',
		unrealisedLoss: '未实现亏损',
		price: '价格',
		name: '名称',
		marketCap: '市值',
		sevenDaysVolume: '7天交易量',
		sevenDaysFees: '7天手续费',
		sevenDaysAverageSupply: '7天平均供应量',
		sevenDaysAverageFee: '7天平均手续费',
		apy: '年化',
		buy: '购买',
		token: '币种',
		copyAddress: '复制地址',
		copied: '已复制',
		balance: '余额',
		connectWallet: '连接钱包'
	},
	wallet:{
		connect: '连接钱包',
		connectAWallet: '连接钱包',
	},
	dlp:{
		tokenInPool: '余额',
		weight: '权重'
	},
	dipx:{
		totalStaked: '总质押量'
	},
	airdrop:{
		claim: '领取空投',
		claimFail: '领取空投失败',
	},
	tx:{
		addLiquidity: 'Pay {0} {1} -> buy {2}',
		removeLiquidity: 'Sell {0} {1}',
		increasePosition: 'Increase {0} {1}',
		decreasePosition: 'Decrease {0} {1}',
		increaseCollateral: 'Increase collateral in {0} {1}',
		decreaseCollateral: 'Decrease collateral in {0} {1}',
		createIncreaseOrder: 'Create increase {0} {1} order ',
		createDecreaseOrder: 'Create decrease {0} {1} order ',
		cancelIncreaseOrder: 'Cancel increase {0} {1} order ',
		cancelDecreaseOrder: 'Cancel decrease {0} {1} order ',
		airdropClaim: 'Airdrop claim'
	},
	app:{
		changeNetwork: "切换网络",
		changeNetworkFail: "切换网络失败",
		waitingChangeNetwork: "切换网络中..."
	},
	setting:{
		title: '系统设置',
		themeSetting: '网站主题',
		userfulLink: '更多链接',
		dashboard: '数据看板',
		dashboardDesc: '系统运行数据',
		languageSetting: '选择语言',
		layoutSetting: '布局设置',
		language: {
			title: '语言'
		},
		theme:{
			title: '主题',
			dark: '深色主题',
			light: '浅色主题',
			auto: '自动'
		},
		layout: {
			title: '布局',
			left: '左边',
			right: '右边'
		},
	},
	header: {
		home: '首页',
		dashboard: '数据看板',
		pool: '流动性',
		trade: '交易',
		referral: '推广',
		airdrop: '空投',
		faucet: 'Faucet',
		earn: '赚取',
		rank: '排行'
	},
	bottomBar: {
		home: '首页',
		dashboard: '仪表盘',
		pool: '流动性',
		trade: '交易',
		earn: '赚取',
		referral: '推广',
		faucet: 'Faucet',
		test: '测试',
		rank: '排行'
	},
	pool:{
		newPool: '添加',
		pools: '流动池',
		add:{
			needApprove: '需要授权',
			addLiquidity: '添加流动性',
			addLiquidityDesc: '等待添加流动性',
			success: '流动性添加成功',
			fail: '流动性添加失败',
			selectToken: '选择币种',
			depositAmount: '充值数额',
			liquidityOut: '收到数额',
			poolInfo: '流动池信息',
			poolName: '名称',
			contract: '合约地址',
			token: '币种',
			totalSupply: '总供应量',
			tokenSupply: '代币供应量',
			unrealisedProfit: '未实现盈利',
			unrealisedLoss: '未实现亏损',
			price: '价格',
			tokenInPool: '币种',
			balance: '余额',
			tokenInfo: '币种信息',
			tokenName: '名称',
			confirm: '确认'
		},
		remove:{
			needApprove: '需要授权',
			removeLiquidity: '移除流动性',
			removeLiquidityDesc: '等待移除流动性',
			success: '流动性移除成功',
			fail: '流动性移除失败',
			selectPool: '选择流动池',
			amounts: '卖出数量',
			tokenOut: '收到币种',
			tokenAmountsOut: '收到数量',
			poolInfo: '流动池信息',
			poolName: '名称',
			contract: '合约地址',
			token: '币种',
			totalSupply: '总供应量',
			price: '价格',
			tokenInfo: '币种信息',
			tokenInPool: '币种',
			balance: '余额',
			tokenName: '名称',
			confirm: '确认',
			tokenSupply: '代币供应量',
			unrealisedProfit: '未实现盈利',
			unrealisedLoss: '未实现亏损',
		},
		select:{
			notPoolAddress: '该币种不在流动池中，请先添加该币种的流动性'
		}
	},
	trade:{
		success: '交易成功',
		fail: "交易失败",
		needApprove: '请在钱包中确认授权',
		positions: '仓位',
		orders: '订单',
		logs: '交易记录',
		all: '所有',
		showAll: '显示所有',
		priceType: {
			market: '市价',
			limit: '限价',
			trigger: '止盈止损'
		},
		limit:{
			longShouldLower: '多单的限价订单必须低于当前价格',
			shortShouldHigher: '空单的限价订单必须高于当前价格',
			orderCreateSuccess: '订单创建成功'
		},
		collateral:{
			depositSuccess: '保证金存入成功',
			depositFail: '保证金存入失败',
			withdrawSuccess: '保证金提取成功',
			withdrawFail: '保证金提取失败'
		},
		actions:{
			IncreasePosition: 'Increase {0} {1}, size:+{2}, collateral:+{3} {4}, fee:{7} {4}, {5} Price:{6} USD',
			DecreasePosition: 'Decrease {0} {1}, size:-{2}, collateral:-{3} {4}, fee:{7} {4}, {5} Price:{6} USD',
			LiquidatePosition: 'Liquidate {0} {1}, size:-{2}, collateral:-{3} {4}, fee:{7} {4}, {5} Price:{6} USD',
			CreateIncreaseOrder: 'Create Order:Increase {0} {1} +{2} {3}, Price:{4}{5} USD',
			CreateDecreaseOrder: 'Create Order:Decrease {0} {1} -{2} {3}, Price:{4}{5} USD',
			CancelIncreaseOrder: 'Cancel Order:Increase {0} {1} +{2} {3}, Price:{4}{5} USD',
			CancelDecreaseOrder: 'Cancel Order:Decrease {0} {1} -{2} {3}, Price:{4}{5} USD',
			UpdateIncreaseOrder: 'Update Order:Increase {0} {1} +{2} {3}, Price:{4}{5} USD',
			UpdateDecreaseOrder: 'Update Order:Decrease {0} {1} -{2} {3}, Price:{4}{5} USD',
			ExecuteIncreaseOrder: 'Execute Order:Increase {0} {1} +{2} {3}, Price:{4} USD',
			ExecuteDecreaseOrder: 'Execute Order:Decrease {0} {1} -{2} {3}, Price:{4} USD',
		}
	},
	order:{
		action:{
			increase: 'Increase',
			decrease: 'Decrease'
		},
		cancelSuccess: '限价订单取消成功',
		cancelFail: '限价订单取消失败',
		createSuccess: '限价订单创建成功'
	},
	referral:{
		setReferrer: '设置推荐人',
		setReferrerInfo: '请在钱包中确认',
		setReferrerFail: '推荐人设置失败',
		setReferrerSuccess: '推荐人设置成功'
	},
	test: {
		faucet: {
			mint: 'Mint',
			mintInfo: 'Please confirm in your wallet',
			mintSuccess: 'Mint Succeed!',
			mintFail: 'Mint Failed'
		}
	},
	earn:{
		vest:{
			title: '归属veDIPX',
			subtitle: '当你选择锁定veDIPX归属DIPX后，你锁定的veDIPX将在6个月内线性归属成DIPX',
			balance: '余额',
			activeVesting: '归属中',
			active: '归属中',
			claimable: '可领取',
			claim: '领取',
			lockToVest: '锁定veDIPX以归属',
			confirm: '确认',
			unlockVesting: '解除veDIPX锁定',
			ruleTitle: '规则',
			rules: [
				"1.veDIPX在任何时候都可以归属成DIPX",
				"2.选择锁定veDIPX归属DIPX后，你锁定的veDIPX将在6个月内线性归属成DIPX",
				"3.选择锁定veDIPX后，可以随时解锁剩下没有被归属的veDIPX",
				"4.获取veDIPX的方式包括：DIPX转成veDIPX，流动性挖矿奖励，交易挖矿奖励等"
			],
			lock: '锁定归属',
			lockDesc: '',
			lockError: '锁定失败',
			unlock: '解锁',
			unlockDesc: '',
			unlockError: '解除锁定失败',
			claimDesc: '',
			claimError: '领取失败'
		},
		stake:{
			title: '流动性挖矿',
			cumulativeReward: '累积奖励',
			estimatedReward: '预估可领取',
			updateAt: '更新于',
			yourBalance: '你的余额',
			yourStaked: '你已质押',
			totalSupply: '总供应量',
			totalStaked: '总质押量',
			todayReward: '今日总奖励',
			ruleTitle: '规则',
			rules: [
				"1.系统每日发放veDIPX作为流动性挖矿的奖励，奖励按时间线性释放到奖励池中",
				"2.通过质押DLP来获得流动性挖矿奖励",
				"3.流动性挖矿奖励根据你质押DLP的时长/数量/占比来确定",
				"4.你可以随时赎回你质押的DLP，赎回后将不再获得流动性挖矿奖励",
				"5.平台每日流动性挖矿的总奖励量可能会根据情况做响应的调整"
			],
			balance: '余额',
			staked: '已质押',
			stakeToEarn: '质押挖矿',
			stake: '质押',
			stakeDesc: '',
			stakeError: '质押失败',
			unstake: '解质押',
			unstakeDesc: '',
			unstakeError: '解除质押失败',
			claim: '领取',
			claimDesc: '',
			claimError: '领取失败',
			refresh: '更新奖励',
			refreshDesc: '',
			refreshError: '更新奖励失败',
		},
		trade:{
			title: '交易挖矿',
			tips: '数据从2023-04-23开始计算',
			yourTradingVolume: '你的交易量',
			yourTodayVolume: '今日交易量',
			estimatedClaimable: '预估可领取',
			cumulative: '累积奖励',
			claim: '领取',
			claimError: '领取失败',
			claimDesc: '',
			totalTradingVolume: '总交易量',
			todayVolume: '今日交易量',
			rewardPerDay: '今日奖励',
			totalCumulativeReward: '累积总奖励',
			ruleTitle: '规则',
			rules: [
				"1.每日发放一定数量的veDIPX做为当天的交易挖矿奖励，奖励数量可能随时会做调整",
				"2.用户的交易挖矿奖励根据他当天DLP的交易量占比来发放",
				"3.用户在第二天可以领取前一天的交易挖矿奖励",
			]
		}
	}
}