import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

const themeValue = localStorage.getItem("theme") || 'auto'
let isDark = themeValue == 'dark'
const scheme = window.matchMedia('(prefers-color-scheme: dark)')
if(themeValue == 'auto'){
  isDark = scheme.matches
}

export default new Vuetify({
  theme: {
    themes:{
      dark: {
        background: '#06070a',
      },
      light: {
        background: '#F3F5FA'
      }
    },
    dark: isDark
  },
});
