import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from '../assets/lang/en'
import zhLocale from '../assets/lang/zh'
import krLocale from '../assets/lang/kr'

Vue.use(VueI18n);

const messages = {
  en: {...enLocale},
  zh: {...zhLocale},
  kr: {...krLocale}
}

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  messages,
});

export default i18n;