<template>
  <div class="d-flex flex-column justify-center align-center" >
    <!-- <img :src="icon" :width="size"> -->
    <inline-svg :src="icon" :width="size" :height="size"></inline-svg>
    <span class="mt-3 text-center" >{{message}}</span>
  </div>
</template>

<script>
export default {
  props: {
    message:{default: ''},
    icon:{default: require('../assets/img/common/empty-box.svg')},
    size:{default:'40'}
  },
  data:()=>({
  })
}
</script>