<template>
  <v-app class="app">
    <app-header></app-header>

    <v-main>
      <div class="d-flex flex-column" style="min-height:100%;">
        <v-container fluid class="pa-0"
          :class="$vuetify.breakpoint.mobile?'mb-4':'mb-10'">
          <div style="max-width:1440px;" class="mx-auto">
            <router-view></router-view>
          </div>
        </v-container>
        <v-spacer></v-spacer>
        <div :class="$vuetify.breakpoint.mobile?'adjust-bottom-nav':''">
          <app-footer></app-footer>
        </div>
      </div>
      <div class="bottom-nav d-flex flex-row" v-if="$vuetify.breakpoint.mobile" style="">
        <v-btn
          v-for="(item, n) in navs"
          style="flex:1;"
          active-class="nav-active"
          exact-active-class="nav-active"
          :exact="false"
          :key="n"
          color="transparent"
          height="56"
          tile
          text
          depressed
          :to="item.to">
          <div
            class="d-flex flex-column align-center py-2 fill-height">
            <inline-svg :src="item.icon" width="24" height="24" class="nav-icon"></inline-svg>
            <!-- <img style="width:24px;height:24px;" :src="item.icon"> -->
            <span class="text-none nav-title">{{$t(item.name)}}</span>
          </div>
        </v-btn>
      </div>
    </v-main>

    <v-snackbar
      v-model="toast.show"
      :color="toast.color"
      app
      bottom
      transition="slide-y-reverse-transition"
      :right="!$vuetify.breakpoint.mobile"
      max-width="350"
      timeout="5000">
      <span class="font-weight-bold">{{ toast.msg }}</span>
      <span v-if="toast.info" class="d-block">{{ toast.info }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="#fff"
          icon
          v-bind="attrs"
          @click="toast.show = false">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <action-dialog :msg="action.msg" :action="action.name" v-model="action.show" :persistent="action.persistent"></action-dialog>
    <loading :msg="loading.msg" :title="loading.title" v-model="loading.show" :persistent="loading.persistent"></loading>

    <connect-wallet-dialog v-model="connectDialog"></connect-wallet-dialog>

    <!-- <coming-soon></coming-soon> -->
  </v-app>
</template>

<script>
import ConnectWalletDialog from './components/ConnectWalletDialog.vue';
import ActionDialog from './components/ActionDialog.vue';
import Loading from './components/Loading.vue';
import utils from './util/utils'
import config from './config'
import AppFooter from './components/AppFooter.vue';
import AppHeader from './components/AppHeader.vue';
import ComingSoon from './components/ComingSoon.vue';
const { ethers } = require("ethers");
import pyth from '@/util/pyth'

export default {
  name: 'App',
  components:{
    ConnectWalletDialog,
    ActionDialog,
    Loading,
    AppFooter,
    AppHeader,
    ComingSoon
  },
  data: () => ({
    bottomTabValue: "",
    connectDialog: false,
    drawer: false,
    account: '',
    toast: {
      show: false,
      msg: '',
      info: '',
      color: '#7a5ff4'
    },
    loading: {
      show: false,
      title: '',
      msg: '',
      persistent:false
    },
    action: {
      show: false,
      name: '',
      msg: '',
      persistent:false
    },
    navs:[
      {name:'bottomBar.home',value:'Home', to:{name:'Dashboard'}, icon: require("./assets/img/navbar/dashboard.svg")},
      {name:'bottomBar.pool',value:'Pool', to:{name:'Pool'}, icon: require("./assets/img/navbar/pool.svg")},
      {name:'bottomBar.trade',value:'Trade', to:{name:'Trade'}, icon: require("./assets/img/navbar/trade.svg")},
      {name:'bottomBar.earn',value:'Earn', to:{name:'Earn'}, icon: require("./assets/img/navbar/earn.svg")},
      {name:'bottomBar.referral',value:'Referral', to:{name:'Referral'}, icon: require("./assets/img/navbar/referral.svg")},
    ],
    timer: null,
    feeDataTimer: null,
    pendingTxs: [],
  }),
  watch:{
    $route(to,from){
      const refer = to.query.refer
      if (refer) {
        this.$store.dispatch("setReferrer",{
          referrer:refer
        })
      }
    }
  },
  mounted(){
    // const scheme = window.matchMedia('(prefers-color-scheme: dark)')
    // const themeValue = localStorage.getItem("theme") || 'auto'
    // if(themeValue == 'auto'){
    //   this.$vuetify.theme.dark = scheme.matches
    // }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      const themeValue = localStorage.getItem("theme") || 'auto'
      if(themeValue == 'auto'){
        if (e.matches) {
          this.$vuetify.theme.dark = true
        } else {
          this.$vuetify.theme.dark = false
        }
      }
    });

    this.$bus.$on("showDrawer",() => {
      this.drawer = true
    })
    this.$bus.$on("appMsg", ({msg,info,color}) => {
      this.toast.msg = msg;
      this.toast.info = info
      this.toast.color = color?color:'#7a5ff4'
      this.toast.show = true
    });
    this.$bus.$on("showLoading", ({title,msg,persistent}) => {
      this.loading = {
        show: true,
        title: title,
        msg: msg,
        persistent: persistent
      }
    });
    this.$bus.$on("hideLoading", () => {
      this.loading = {
        show: false,
        title: '',
        msg: '',
        persistent: false
      }
    });
    this.$bus.$on("showAction", ({name,msg,persistent}) => {
      this.action = {
        show: true,
        name: name,
        msg: msg,
        persistent: persistent
      }
    });
    this.$bus.$on("hideAction", () => {
      this.action = {
        show: false,
        name: '',
        msg: '',
        persistent: false
      }
    });

    this.$bus.$on("walletConnect", (account) => {
      this.account = account;
      this.$store.dispatch("accountChange", {
        account: account
      });
    });

    this.$bus.$on("networkConnect", (newNetwork) => {
      this.$store.dispatch("chainOptionChange",{})
      this.queryFeeData()
    });
    
    this.$chain.on("network", (newNetwork, oldNetwork)=>{
      if (oldNetwork) {
        this.$bus.$emit("networkConnect", newNetwork)
        this.$store.dispatch("networkChange", {
          chainId: newNetwork.chainId
        });
        window.location.reload();
      }
    })
    this.$chain.provider().provider.on("accountsChanged", (accounts)=>{
      this.$bus.$emit("walletConnect", accounts[0])
    })

    this.$bus.$on("PendingTx", (pendingTx) => {
      this.$store.dispatch("pendingTx",pendingTx)
      this.$chain.wait(pendingTx.tx).then(result => {
        this.$bus.$emit("ConfirmTx",{
          ...pendingTx,
          status: 'success'
        })
        this.$api.syncTxEvent(pendingTx.txhash)
      }).catch(e => {
        this.$bus.$emit("ConfirmTx",{
          ...pendingTx,
          status: 'fail'
        })
        this.$bus.$emit("TxError",pendingTx)
        this.$ui.showError({
          error: e,
          msg: this.$t("common.txConfirmError")
        })
      })
    });
    this.$bus.$on("ConfirmTx", ({txhash,status}) => {
      this.$store.dispatch("confirmTx",{txhash,status})
    });

    if(this.$store.getters.isLogin){
      this.$bus.$emit("walletConnect", this.$store.state.account)
    }

    this.tryGetAccount()
    if(utils.isMetamaskInstalled()){
      this.$chain.getNetwork().then(network => {
        this.$store.dispatch("networkChange", {
          chainId: network.chainId
        });
        this.$bus.$emit("networkConnect", network)
      })
    }

    this.timer = setInterval(() => {
      this.queryDlpPrice()
      this.queryIndexPriceOnChain()
    }, 1000*5);
    this.feeDataTimer = setInterval(() => {
      this.queryFeeData()
    }, 1000*30);

    // this.queryAllIndexPrice()
    this.queryDlpPrice()
    this.queryIndexPriceOnChain()
    this.queryFeeData()

    setTimeout(e => {
      let safeTop = getComputedStyle(document.documentElement).getPropertyValue("--safeTop");
      let safeBottom = getComputedStyle(document.documentElement).getPropertyValue("--safeBottom")
      safeTop = parseInt(safeTop.replace('px', ''))
      safeBottom = parseInt(safeBottom.replace('px', ''))
      this.$store.state.safeTop = safeTop
      this.$store.state.safeBottom = safeBottom
    }, 300)
  },
  beforeDestroy(){
    if(this.timer){
      clearInterval(this.timer)
      this.timer = null
    }
    if(this.feeDataTimer){
      clearInterval(this.feeDataTimer)
      this.feeDataTimer = null
    }

    this.$bus.$off()
  },
  methods:{
    queryFeeData(){
      this.$api.feeData().then(resp => {
        const apiFeeData = resp.data
        const gasOptions = {
          low: ethers.BigNumber.from(apiFeeData.low),
          market: ethers.BigNumber.from(apiFeeData.low),
          aggressive: ethers.BigNumber.from(apiFeeData.aggressive),
          suggest: ethers.BigNumber.from(apiFeeData.suggest),
          force: apiFeeData.force?true:false,
          updateAt: apiFeeData.updateAt
        }
        this.$store.dispatch("chainOptionChange", gasOptions)
      }).catch(error => {
        console.log(error)
      })
      // this.$chain.provider().getFeeData().then(async chainFeeData => {
      //   let feeData = chainFeeData
      //   try {
      //     const resp = await this.$api.feeData()
      //     const apiFeeData = resp.data
      //     if(feeData.lastBaseFeePerGas.lt(apiFeeData.lastBaseFeePerGas)){
      //       feeData.lastBaseFeePerGas = ethers.BigNumber.from(apiFeeData.lastBaseFeePerGas)
      //     }
      //     if(feeData.maxFeePerGas.lt(apiFeeData.maxFeePerGas)){
      //       feeData.maxFeePerGas = ethers.BigNumber.from(apiFeeData.maxFeePerGas)
      //     }
      //     if(feeData.maxPriorityFeePerGas.lt(apiFeeData.maxPriorityFeePerGas)){
      //       feeData.maxPriorityFeePerGas = ethers.BigNumber.from(apiFeeData.maxPriorityFeePerGas)
      //     }
      //     if(feeData.gasPrice.lt(apiFeeData.gasPrice)){
      //       feeData.gasPrice = ethers.BigNumber.from(apiFeeData.gasPrice)
      //     }  
      //   } catch (error) {
      //     console.log("api getFeeData error")
      //   }
        
      //   this.$store.dispatch("chainOptionChange",feeData)
      // })
    },
    goto(route){
      this.drawer = false
      if(route.name != this.$router.currentRoute.name){
        this.$router.push(route)
      }
    },
    tryGetAccount(){
      this.$chain.signer().then(signer => {
        this.$bus.$emit("walletConnect", signer)
      }).catch(error => {
      })
    },
    queryDlpPrice(){
      const config = this.$store.getters.config
      if(config && config.chain){
        const iRouter = this.$chain.router().interface
        let calls = [
          {
            target: config.chain.router.address,
            callData: iRouter.encodeFunctionData("getPoolPrice",[config.chain.dlp.address,true,true,true]),
          },
          {
            target: config.chain.router.address,
            callData: iRouter.encodeFunctionData("getPoolPrice",[config.chain.dlp.address,true,false,false]),
          }
        ]

        // this.$api.multicall(calls).then(resp => {
        //   const returnData = resp.data
        //   this.poolPriceCallback(config.chain.dlp.address,returnData)
        // })
        this.$chain.multicall().callStatic.multicall(calls).then(resp => {
          const returnData = resp.returnData
          this.poolPriceCallback(config.chain.dlp.address,returnData)
        })
      }
    },
    poolPriceCallback(pool,returnData){
      const iRouter = this.$chain.router().interface
      const result0 = iRouter.decodeFunctionResult("getPoolPrice",returnData[0])
      const priceWithPnl = result0[0]
      const result1 = iRouter.decodeFunctionResult("getPoolPrice",returnData[1])
      const price = result1[0]
      
      this.$market.setPoolPrice(
        pool,
        price,
        priceWithPnl,
      )

      this.$bus.$emit("poolPriceTicker",{
        pool: pool,
        price: price,
        priceWithPnl: priceWithPnl
      })
    },
    queryIndexPriceOnChain(){
      const indexTokens = this.$store.getters.config.chain.indexTokens
      let calls = []
      const pricefeed = this.$chain.pricefeed()
      for (let i = 0; i < indexTokens.length; i++) {
        calls.push({
          target: pricefeed.address,
          callData: pricefeed.interface.encodeFunctionData("getPrice",[indexTokens[i].address,false]),
        })
        calls.push({
          target: pricefeed.address,
          callData: pricefeed.interface.encodeFunctionData("getPrice",[indexTokens[i].address,true]),
        })
      }
      
      this.$chain.multicall().callStatic.multicall(calls).then(resp => {
        const returnData = resp.returnData
        for (let i = 0; i < indexTokens.length; i++) {
          const minPrice = pricefeed.interface.decodeFunctionResult("getPrice",returnData[i*2])[0]
          const maxPrice = pricefeed.interface.decodeFunctionResult("getPrice",returnData[i*2+1])[0]
          
          this.$market.setIndexPrice({
            index: indexTokens[i].address,
            max: maxPrice,
            min: minPrice
          })
          this.$bus.$emit("indexPriceTicker",{
            index: indexTokens[i].address,
            min: minPrice,
            max: maxPrice
          })
        }
      })
    },
    queryAllIndexPrice(){
      this.$api.prices().then(resp => {
        const prices = resp.data
        for (let i = 0; i < prices.length; i++) {
          const indexToken = prices[i].address
          const maxPrice = ethers.BigNumber.from(prices[i].max)
          const minPrice = ethers.BigNumber.from(prices[i].min)
          this.$market.setIndexPrice({
            index: indexToken,
            max: maxPrice,
            min:minPrice
          })
          this.$bus.$emit("indexPriceTicker",{
            index: indexToken,
            min: minPrice,
            max: maxPrice
          })
        }
      })
    },
  }
};
</script>

<style scoped>

.bg{
  /* background: #06070a; */
}
.adjust-bottom-nav{
  margin-bottom: env(safe-area-inset-bottom);
  padding-bottom: 56px;
}
.nav-active span{
  color: #fff!important;
}
.nav-active img{
  filter: brightness(0) invert(1);
}

</style>