<template>
  <div class="dialog-bg"
    :class="$vuetify.breakpoint.mobile?'bt':'ba'">
    <div v-if="!showLanguage">
      <div class="pa-3">
        <v-btn block class="primary-btn text-none font-weight-bold rounded-lg" depressed @click="connect">{{$t('common.connectWallet')}}</v-btn>
      </div>
      <div style="height:1px;background:#ffffff14;" ></div>
      <v-list color="transparent" :dense="!$vuetify.breakpoint.mobile" class="py-0" flat>
        <v-list-item-group>
          <v-list-item
            @click="showLanguage=true"
            style="cursor:pointer;" class="px-3" >
            <v-list-item-content>
              <v-list-item-title class="d-flex flex-row align-center">
                <span>{{$t("setting.language")}}</span>
              </v-list-item-title>
            </v-list-item-content>
            <span class="plain-text">{{ langName($i18n.locale) }}</span>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <app-setting></app-setting>
      <v-sheet v-if="$vuetify.breakpoint.mobile" color="transparent" style="height:200px;"></v-sheet>
    </div>
    <div v-else class="d-flex flex-column">
      <div class="d-flex flex-row align-center px-2 py-3">
        <v-btn icon small @click="showLanguage = false">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span>{{$t("setting.language")}}</span>
        <v-spacer></v-spacer>
        <v-btn icon small>
        </v-btn>
      </div>
      <v-divider class="mx-2"></v-divider>
      <LanguageSetting style="overflow-y:scroll;height: 250px;" @langChanged="showLanguage=false"></LanguageSetting>
    </div>
  </div>
</template>

<script>
import AppSetting from "./AppSetting.vue"
import LanguageSetting from "./LanguageSetting.vue"

export default {
  components:{
    AppSetting,
    LanguageSetting
},
  props:["connect","show"],
  data:()=>({
    langs: [],
    showLanguage: false,
  }),
  watch:{
    show(newVal, oldVal){
      if (newVal) {
        this.showLanguage = false
      }
    }
  },
  mounted(){
    this.langs = this.$store.getters.config.app.languages
  },
  methods:{
    langName(lang){
      const langs = this.$store.getters.config.app.languages
      for (let i = 0; i < langs.length; i++) {
        if(langs[i].value == lang){
          return langs[i].name
        }
      }
      return ''
    },
    changeLang(lang){
      this.$i18n.locale = lang
      localStorage.setItem("lang",lang)
    },
  }
}
</script>