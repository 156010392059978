const { ethers } = require("ethers");

// import config from '../config'
var moment = require('moment');
import utils from '../util/utils'
import store from '../store'

const erc20ABI = require("../config/abi/erc20.json")
const singleLPABI = require("../config/abi/SingleLP.json")
const mixedLpABI = require("../config/abi/MixedLP.json")

const chain = {
  provider(){
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    return provider
  },
  on(event,callback){
    this.provider().on(event, callback);
  },
  getNetwork() {
    return this.provider().getNetwork()
  },
  addChain(chainConfig) {
    return window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [chainConfig],
    });
  },
  switchChain(chainId){
    return window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{chainId: chainId}],
    });
  },
  signer(){
    return this.provider().getSigner().getAddress()
  },
  requestPermission(){
    return window.ethereum.request({
        method: "wallet_requestPermissions",
        params: [{ eth_accounts: {} }],
    });
  },
  requestAccount(){
    return this.provider().send("eth_requestAccounts", []);
  },
  async wait(tx,confirmations=null,confirmationCallback=null){
    const receipt = await tx.wait(1)
    return receipt
    // let receipt = null;
    // let requireConfirmations = confirmations?confirmations:store.state.defaultConfirmations
    // while(receipt == null || receipt.confirmations<requireConfirmations){
    //   receipt = await this.provider().getTransactionReceipt(tx.hash)
    //   if(receipt){
    //     console.log(`Receipt confirmations:`, receipt.confirmations);
    //     if(confirmationCallback){
    //       confirmationCallback(receipt)
    //     }
    //     if(receipt.status == 0){
    //       throw `transaction error:${receipt.transactionHash}`
    //     }
    //   }
    //   const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));
    //   sleep(1000)
    //   continue;
    // }
    // return receipt
  },
  dlp(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.dlp.address, 
      config.chain.dlp.abi, 
      this.provider()
    );
  },
  distributor(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.distributor.address, 
      config.chain.distributor.abi, 
      this.provider()
    );
  },
  erc20(address){
    return new ethers.Contract(
      address, 
      erc20ABI, 
      this.provider()
    );
  },
  genesisPass(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.genesisPass.address, 
      config.chain.genesisPass.abi, 
      this.provider()
    );
  },
  vedipx(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.veDIPX.address, 
      config.chain.veDIPX.abi, 
      this.provider()
    );
  },
  vesting(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.vesting.address, 
      config.chain.vesting.abi, 
      this.provider()
    );
  },
  dlpYield(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.dlpYield.address, 
      config.chain.dlpYield.abi, 
      this.provider()
    );
  },
  tradingYield(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.tradingYield.address, 
      config.chain.tradingYield.abi, 
      this.provider()
    );
  },
  airdrop(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.airdrop.address, 
      config.chain.airdrop.abi, 
      this.provider()
    );
  },
  multicall(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.multicall.address, 
      config.chain.multicall.abi, 
      this.provider()
    );
  },
  handler(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.handler.address, 
      config.chain.handler.abi, 
      this.provider()
    );
  },
  tradingYield(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.tradingYield.address, 
      config.chain.tradingYield.abi, 
      this.provider()
    );
  },
  erc20Info(address){
    const ierc20 = new ethers.utils.Interface(erc20ABI)
    const functions = [
      {name: "name",params: []},
      {name: "symbol",params: []},
      {name: "decimals",params: []},
      {name: "totalSupply",params: []},
    ]
    var calls = functions.map(f => {
      const callData = ierc20.encodeFunctionData(f.name,f.params)
      return {
        target:address,
        callData:callData
      }
    })
    return this.multicall().callStatic.multicall(calls).then(resp => {
      var datas ={}
      const returnData = resp.returnData
      for (let i = 0; i < returnData.length; i++) {
        const result = ierc20.decodeFunctionResult(functions[i].name,returnData[i])
        datas[functions[i].name] = result[0]
      }
      return datas
    })
  },
  getIndexPrices(){
    const config = store.getters.config
    const iPricefeed = new ethers.utils.Interface(config.chain.vaultPriceFeed.abi)
    const tokens = config.chain.indexTokens.map(item => item.address)
    let callFunctions = []
    for (let i = 0; i < tokens.length; i++) {
      callFunctions.push(
        {name: "getPrice",params: [tokens[i],true]},
        {name: "getPrice",params: [tokens[i],false]}
      )
    }
    var calls = callFunctions.map(f => {
      const callData = iPricefeed.encodeFunctionData(f.name,f.params)
      return {
        target:config.chain.vaultPriceFeed.address,
        callData:callData
      }
    })
    return this.multicall().callStatic.multicall(calls).then(resp => {
      var datas =[]
      const returnData = resp.returnData
      for (let i = 0; i < returnData.length; i++) {
        const result = iPricefeed.decodeFunctionResult(callFunctions[i].name,returnData[i])
        datas.push({
          indexToken:callFunctions[i].params[0], 
          maximise:callFunctions[i].params[1],
          price:result[0]
        })
      }
      return datas
    })
  },
  singleLP(address){
    return new ethers.Contract(
      address, 
      singleLPABI, 
      this.provider()
    );
  },
  mixedLP(address){
    return new ethers.Contract(
      address, 
      mixedLpABI, 
      this.provider()
    );
  },
  ethBalanceOf(owner){
    return this.provider().getBalance(owner)
  },
  balanceOf(token,owner){
    return this.erc20(token).balanceOf(owner)
  },
  totalSupply(token){
    return this.erc20(token).totalSupply()
  },
  allowance(token,owner,spender){
    return this.erc20(token).allowance(owner,spender)
  },
  approveToRouter(token,amount){
    const config = store.getters.config
    let erc20 = this.erc20(token).connect(this.provider().getSigner())
    return erc20.approve(config.chain.router.address,amount)
  },
  approveToOrderBook(token,amount){
    const config = store.getters.config
    let erc20 = this.erc20(token).connect(this.provider().getSigner())
    return erc20.approve(config.chain.orderBook.address,amount)
  },
  lpManager(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.lpManager.address, 
      config.chain.lpManager.abi, 
      this.provider()
    );
  },
  storage(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.storage.address, 
      config.chain.storage.abi, 
      this.provider()
    );
  },
  positionManager(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.positionManager.address, 
      config.chain.positionManager.abi, 
      this.provider()
    );
  },
  router(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.router.address, 
      config.chain.router.abi, 
      this.provider()
    );
  },
  orderBook(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.orderBook.address, 
      config.chain.orderBook.abi, 
      this.provider()
    );
  },
  referral(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.referral.address, 
      config.chain.referral.abi, 
      this.provider()
    );
  },
  pricefeed(){
    const config = store.getters.config
    return new ethers.Contract(
      config.chain.vaultPriceFeed.address, 
      config.chain.vaultPriceFeed.abi, 
      this.provider()
    );
  },
  addLiquidity(token,targetPool,amountIn,price,priceUpdateData){
    const priceData = priceUpdateData?priceUpdateData:[]
    return this.signer().then(signer => {
      let referrer = store.getters.referrer
      if(!ethers.utils.isAddress(referrer) || utils.addressEq(signer, referrer)){
        referrer = ethers.constants.AddressZero
      }
      let options = store.getters.chainOption
      if(utils.isNativeCurrency(token)){
        options.value = amountIn
        return this.router().connect(this.provider().getSigner())
          .addLiquidityNative(targetPool,amountIn,signer,price,referrer,priceData, options)
      }else{
        return this.router().connect(this.provider().getSigner())
          .addLiquidity(token,targetPool,amountIn,signer,price,referrer,priceData, options)
      }
    })
  },
  removeLiquidity(pool,liquidity, outToken,price,priceUpdateData){
    const priceData = priceUpdateData?priceUpdateData:[]
    return this.signer().then(signer => {
      let referrer = store.getters.referrer
      if(!ethers.utils.isAddress(referrer) || utils.addressEq(signer, referrer)){
        referrer = ethers.constants.AddressZero
      }
      return this.router().connect(this.provider().getSigner())
          .removeLiquidity(pool,outToken,liquidity,signer,price,referrer,priceData,store.getters.chainOption)
      
    })
  }
}

export default {
  install: function(Vue, options) {
    Vue.prototype.$chain = chain;
  },
};