const { EvmPriceServiceConnection } = require("@pythnetwork/pyth-evm-js");
import store from '../store'

let connections = {}
function getConfig() {
  return store.getters.config
}
function getConnection() {
  const config = getConfig()
  if(connections[config.chain.chainId]){
    return connections[config.chain.chainId]
  }
  const connection = new EvmPriceServiceConnection(
    config.chain.pyth.endpoint
  );
  connections[config.chain.chainId] = connection
  return connection
}

function getPriceFeedsUpdateData(indexToken) {
  const config = getConfig()
  let priceIds = []
  for (let i = 0; i < config.chain.pyth.priceIds.length; i++) {
    const feed = config.chain.pyth.priceIds[i];
    if (feed.token.toLowerCase() == indexToken.toLowerCase()) {
      priceIds.push(feed.priceId)
    }
  }
  return getConnection().getPriceFeedsUpdateData(priceIds);
}

export default {
  getPriceFeedsUpdateData
}