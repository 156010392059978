
import utils from '../util/utils'
import configs from "../config"
import { ethers } from 'ethers'

const POOL_PRICE_ZERO = {
  price: ethers.constants.Zero,
  priceWithPnl: ethers.constants.Zero
}

let indexPriceDatas = {}
let poolPriceDatas = {}

const market = {
  minIndexPrice(index){
    if(!index){
      return null
    }
    const data = this.indexPrice(index.toLowerCase())
    if(!data){
      return null
    }
    return data.min
  },
  maxIndexPrice(index){
    if(!index){
      return null
    }
    const data = this.indexPrice(index.toLowerCase())
    if(!data){
      return null
    }
    return data.max
  },
  indexPrice(index){
    if(!index){
      return null
    }
    return indexPriceDatas[index.toLowerCase()]
  },
  poolPrice(pool){
    if(!pool){
      return POOL_PRICE_ZERO
    }

    const data = poolPriceDatas[pool.toLowerCase()]
    return data?data:POOL_PRICE_ZERO
  },
  setPoolPrice(pool,price,priceWithPnl){
    if(!poolPriceDatas[pool.toLowerCase()]){
      poolPriceDatas[pool.toLowerCase()] = {}
    }
    
    if(price){
      poolPriceDatas[pool.toLowerCase()].price = price
    }

    if(priceWithPnl){
      poolPriceDatas[pool.toLowerCase()].priceWithPnl = priceWithPnl
    }
  },
  setIndexPrice({index,max,min}){
    if(!indexPriceDatas[index.toLowerCase()]){
      indexPriceDatas[index.toLowerCase()] = {}
    }
    if(max){
      indexPriceDatas[index.toLowerCase()].max = max
    }
    if(min){
      indexPriceDatas[index.toLowerCase()].min = min
    }
    // if(index.toLowerCase() == '0x4137D3D1d8DE0D9a15552039725f51596F65851e'.toLowerCase()){
    //   if(max+''=='16435755000000'){
    //     console.log(index,max+'',min+'')
    //   }
    // }
    
  }
}

export default {
  install: function(Vue, options) {
    // const config = configs.get(0)
    // const networks = config.app.networks
    // for (let i = 0; i < networks.length; i++) {
    //   const network = networks[i];
    //   const networkConfig = configs.get(network.chainId)
    //   if(networkConfig.chain){
    //     networkConfig.chain.indexTokens.forEach(item => {
    //       indexPriceDatas[item.address.toLowerCase()] = {}
    //     })
    //   }
      
    // }
    
    Vue.prototype.$market = market;
  },
};