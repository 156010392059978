<template>
  <div class="dialog-bg" style="min-width:320px;"
    :style="!$vuetify.breakpoint.mobile?'width:420px;':''"
    :class="$vuetify.breakpoint.mobile?'bt':'ba'">
    <div v-if="!showTx && !showLanguage">
      <div class="d-flex flex-row align-center px-5 pt-4">
        <v-avatar size="28" class="mr-2 primary">
          <v-img :src="account | identicon"></v-img>
        </v-avatar>
        <span class="font-weight-bold">{{account | address(4)}}</span>
        <v-spacer></v-spacer>
        <div 
          v-tooltip="{
            triggers: ['hover'],
            placement: 'bottom',
            handleResize: true,
            popperTriggers: ['hover'],
            content: copyDone?$t('common.copied'):$t('common.copyAddress')
          }"
          @click="copyAddress"
          class="d-flex flex-row justify-center align-center rounded-circle container-secondary-bg"
          style="width:36px;height:36px;cursor: pointer;">
          <v-icon size="16">{{copyDone?'mdi-check':'mdi-content-copy'}}</v-icon>
        </div>
        <a 
          class="d-flex flex-row justify-center align-center rounded-circle ml-2 container-secondary-bg"
          :href="account | explorer('address')" target="_blank"
          style="width:36px;height:36px;cursor: pointer;">
          <v-icon size="18">mdi-open-in-new</v-icon>
        </a>
        <div 
          @click="switchAccount"
          class="d-flex flex-row justify-center align-center rounded-circle ml-2 container-secondary-bg"
          style="width:36px;height:36px;cursor: pointer;">
          <v-icon size="18">mdi-account-box-multiple</v-icon>
        </div>
      </div>
      <div class="text-center px-3 py-10">
        <div class="text-h5">{{dlpBalance | unit(18,2)}} DLP</div>
        <div class="plain-text text--darken-1">≈ ${{dlpValue | unit(18,2)}} USD</div>
      </div>
      <div style="height:1px;background:#ffffff14;" class="mx-3"></div>

      <v-list color="transparent" class="py-0" flat>
        <v-list-item-group>
          <v-list-item
            @click="showTx=true"
            style="cursor:pointer;" class="px-3" >
            <v-list-item-content>
              <v-list-item-title class="d-flex flex-row align-center">
                <span class="plain-text">Transactions</span>
                <span v-if="pendingTxs && pendingTxs.length>0"
                  style="line-height: 1;"
                  class="px-2 py-1 ml-2 mr-6 rounded cyan text-caption font-weight-bold">
                  {{ pendingTxs.length }} Pending
                </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-icon class="plain-text">mdi-chevron-right</v-icon>
          </v-list-item>
          <!-- <v-list-item
            @click="showLanguage=true"
            style="cursor:pointer;" class="px-3" >
            <v-list-item-content>
              <v-list-item-title class="d-flex flex-row align-center">
                <span>{{$t("setting.language")}}</span>
              </v-list-item-title>
            </v-list-item-content>
            <span class="plain-text">{{ langName($i18n.locale) }}</span>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
      <!-- <app-setting></app-setting> -->
    </div>
    <div v-else-if="showTx" class="d-flex flex-column">
      <div class="d-flex flex-row align-center px-2 py-3">
        <v-btn icon @click="showTx = false" class="rounded-lg">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span>Transactions</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="clearAll" class="rounded-lg">
          <v-icon small>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
      <v-divider class="mx-2"></v-divider>
      <UserTransactions style="overflow-y:scroll;max-height: 350px;min-height: 200px;"></UserTransactions>
    </div>
    <!-- <div v-else-if="showLanguage" class="d-flex flex-column">
      <div class="d-flex flex-row align-center px-2 py-3">
        <v-btn icon small @click="showLanguage = false">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span>{{$t("setting.language")}}</span>
        <v-spacer></v-spacer>
        <v-btn icon small disabled>
        </v-btn>
      </div>
      <v-divider class="mx-2"></v-divider>
      <LanguageSetting style="overflow-y:scroll;height: 250px;" @langChanged="showLanguage=false"></LanguageSetting>
    </div> -->
  </div>
</template>

<script>
import { ethers } from 'ethers';
import utils from '@/util/utils'
import AppSetting from './AppSetting.vue';
import UserTransactions from './UserTransactions.vue';
import LanguageSetting from './LanguageSetting.vue';

export default {
  components: { AppSetting, UserTransactions,LanguageSetting },
  props:["close","show"],
  data:()=>({
    showTx: false,
    showLanguage: false,
    account: '',
    langs: [],
    dlpBalance: ethers.constants.Zero,
    dlpPrice: ethers.constants.Zero,
    copyDone: false,
    dlpTimer: null,
    pendingTxs: [],
  }),
  computed:{
    dlpValue(){
      return this.dlpBalance.mul(this.dlpPrice).div(ethers.utils.parseUnits("1",10))
    }
  },
  watch:{
    show(newVal, oldVal){
      if (newVal) {
        this.showTx = false
        this.showLanguage = false
      }
    }
  },
  mounted(){
    const config = this.$store.getters.config
    this.langs = config.app.languages
    this.account = this.$store.state.account
    this.$bus.$on("walletConnect", (account) => {
      this.account = account;
      this.pendingTxs = []
      this.queryDlpBalance()
    });
    this.$bus.$on("networkConnect", (network) => {
      this.pendingTxs = []
    });

    this.dlpPrice = this.$market.poolPrice(config.chain.dlp.address).priceWithPnl
    this.$bus.$on("poolPriceTicker", ({pool,price,priceWithPnl}) => {
      if(utils.isDlp(pool)){
        this.dlpPrice = priceWithPnl
      }
    });
    this.pendingTxs = this.$store.state.pendingTxs
    this.$bus.$on("PendingTx", (tx) => {
      this.pendingTxs= [
        tx,
        ...this.pendingTxs.filter(item => utils.addressEq(item.txhash,tx.txhash))
      ]
    });
    this.$bus.$on("ConfirmTx", ({txhash}) => {
      this.pendingTxs = this.pendingTxs.filter(tx => !utils.addressEq(tx.txhash,txhash))
    });

    this.updateDlp()
    this.dlpTimer = setInterval(async() => {
      this.updateDlp()
    }, 1000*10);
  },
  beforeDestroy(){
    if(this.dlpTimer){
      clearInterval(this.dlpTimer)
      this.dlpTimer = null
    }
  },
  methods:{
    langName(lang){
      const langs = this.$store.getters.config.app.languages
      for (let i = 0; i < langs.length; i++) {
        if(langs[i].value == lang){
          return langs[i].name
        }
      }
      return ''
    },
    changeLayout(layout){
      localStorage.setItem("layout",layout)
      this.$bus.$emit("layoutChanged", layout)
    },
    changeLang(lang){
      this.$i18n.locale = lang
      localStorage.setItem("lang",lang)
      // this.$emit("close")
    },
    async updateDlp(){
      const config = this.$store.getters.config
      if(config && config.chain && this.account){
        const calls = [
          {
            target:config.chain.dlp.address, 
            callData: this.$chain.dlp().interface.encodeFunctionData("balanceOf",[this.account]),
          }
        ]
        this.$chain.multicall().callStatic.multicall(calls).then(resp => {
          const returnData = resp.returnData
          // const returnData = resp.data
          const balanceResult = this.$chain.dlp().interface.decodeFunctionResult("balanceOf",returnData[0])
          const balance = balanceResult[0]
          this.dlpBalance = balance
        })
      }
    },
    queryDlpPrice(){
      const config = this.$store.getters.config
      if(config && config.chain){
        this.$chain.router().getPoolPrice(config.chain.dlp.address,true,true,true)
          .then(price => {
            this.dlpPrice = price
          })
      }
    },
    queryDlpBalance(){
      if(this.account){
        this.$chain.dlp().balanceOf(this.account).then(balance => {
          this.dlpBalance = balance
        })
      }
    },
    copyAddress(){
      this.$copyText(this.account).then(()=>{
        this.copyDone = true
        utils.sleep(1000*3).then(() => {
          this.copyDone = false
        })
      }, ()=>{
        this.$ui.showToast({msg:'failed',color:'error'})
      })
    },
    switchAccount(){
      this.$chain.requestPermission().then(permissions => {
        this.$chain.requestAccount().then(accounts => {
          if(accounts.length>0){
            this.$bus.$emit("walletConnect", accounts[0])
          }
        })
      })
    },
    clearAll(){
      this.$store.dispatch("clearTxs")
      this.$bus.$emit("ClearTxs")
    }
  }
}
</script>