import { ethers } from 'ethers';
import Vue from 'vue'
import Vuex from 'vuex'
import configs from "../config"
import utils from '../util/utils'
var moment = require('moment');

Vue.use(Vuex)

function isEmpty(obj) {
  if (typeof obj == "undefined" || obj == null || obj == "") {
      return true;
  } else {
      return false;
  }
}

export default new Vuex.Store({
  state: {
    defaultConfirmations: localStorage.getItem('defaultConfirmations') || 1,
    referrer: localStorage.getItem('referrer')||ethers.constants.AddressZero,
    historyTxs: JSON.parse(localStorage.getItem('historyTxs'))||[],
    pendingTxs: [],
    account: '',
    chainId: 0,
    chainOption: {}
  },
  getters:{
    chainOption: (state) => {
      if(state.chainOption && state.chainOption.suggest 
        && state.chainOption.updateAt >= moment().unix()-60*5){
          return {
            gasPrice: ethers.BigNumber.from(state.chainOption.suggest)
          }
        // if(state.chainOption.maxFeePerGas && state.chainOption.maxPriorityFeePerGas){
        //   return {
        //     maxFeePerGas: ethers.BigNumber.from(state.chainOption.maxFeePerGas),
        //     maxPriorityFeePerGas: ethers.BigNumber.from(state.chainOption.maxPriorityFeePerGas),
        //   }
        // }
      }
      return {}
    },
    referrer: state => state.referrer,
    isLogin: state => !isEmpty(state.account),
    localStorageTxs: state => localStorage.getItem('historyTxs'),
    userTxs: state => state.historyTxs.filter(tx => utils.addressEq(tx.from,state.account)),
    config: state => configs.get(state.chainId)
  },
  mutations: {
    setAccount(state, payload){
      state.account = payload.account
    },
    setNetwork(state, payload){
      const chainId = payload.chainId
      if(configs.get(chainId).chain){
        state.chainId = chainId
      }else{
        state.chainId = 0
      }
    },
    setChainOption(state, payload){
      state.chainOption = payload
    },
    setReferrer(state, payload){
      const referrer = payload.referrer||ethers.constants.AddressZero
      localStorage.setItem('referrer',referrer)
      state.referrer = referrer
    },
    addTx(state, payload){
      const tx = {
        from: payload.from,
        txhash: payload.txhash,
        action: payload.action,
        rowData: payload.rowData
      }
      state.pendingTxs = state.pendingTxs.filter(item => item.txhash==tx.txhash)
      state.pendingTxs.unshift(tx)

      // state.historyTxs = JSON.parse(localStorage.getItem('historyTxs'))||[]
      state.historyTxs = state.historyTxs.filter(item => item.txhash!=tx.txhash)
      state.historyTxs.unshift(tx)
      localStorage.setItem('historyTxs',JSON.stringify(state.historyTxs))
    },
    removePendingTx(state, payload){
      state.pendingTxs = state.pendingTxs.filter(tx => !utils.addressEq(tx.txhash,payload.txhash))
      if(payload.status=='fail'){
        state.historyTxs.forEach(tx => {
          if(tx.txhash == payload.txhash){
            tx.status = 'fail'
          }
        });
        localStorage.setItem('historyTxs',JSON.stringify(state.historyTxs))
      }
    },
    removeTxs(state, payload){
      // state.historyTxs = JSON.parse(localStorage.getItem('historyTxs'))||[]
      state.historyTxs = state.historyTxs.filter(tx => !utils.addressEq(tx.from,state.account))
      localStorage.setItem('historyTxs',JSON.stringify(state.historyTxs))
    },
    setTxFail(state, payload){
      state.pendingTxs = state.pendingTxs.filter(tx => !utils.addressEq(tx.txhash,payload.txhash))
      state.historyTxs = state.historyTxs.forEach(tx => {
        if(tx.txhash == payload.txhash){
          tx.status = 'fail'
        }
      });
      localStorage.setItem('historyTxs',JSON.stringify(state.historyTxs))
    }
  },
  actions: {
    chainOptionChange({commit}, payload){
      commit("setChainOption", payload)
    },
    setReferrer({commit}, payload){
      commit("setReferrer", payload)
    },
    accountChange({ commit }, payload){
      commit("setAccount", payload)
    },
    networkChange( { commit }, payload){
      commit("setNetwork", payload)
    },
    pendingTx({commit}, payload){
      commit("addTx", payload)
    },
    confirmTx({commit}, payload){
      commit("removePendingTx", payload)
    },
    clearTxs({commit}, payload){
      commit("removeTxs", payload)
    }

  },
  modules: {
  }
})