const localhostConfig =  {
  apiServer: "http://localhost:9797",
}
const arbitrumConfig =  {
  apiServer: "https://api.dipx.io/arbitrum",
}

export default {
  '1337': localhostConfig,
  '42161': arbitrumConfig
}