<template>
  <div class="d-flex flex-column">
  <v-list color="transparent" class="py-0">
    <v-list-item-group>
      <v-list-item v-for="(lang,n) in langs" 
        :key="n"
        style="cursor:pointer;" class="px-6" @click="changeLang(lang.value)">
        <v-list-item-content>
          <v-list-item-title>{{lang.name}}</v-list-item-title>
        </v-list-item-content>
        <v-icon small v-if="$i18n.locale==lang.value">mdi-check</v-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
  <v-spacer></v-spacer>
  <div class="d-flex flex-row align-center justify-center pt-3 pb-6">
    <a class="plain-text text-body-2 text-center px-6" style="width: 250px;" 
      href="https://discord.gg/69SRBsG7ZA" target="_blank">
      <span>Join our Discord, submit your language</span>
    </a>
  </div>
  </div>
</template>

<script>
export default{
  data:()=>({
    langs: [],
  }),
  mounted(){
    this.langs = this.$store.getters.config.app.languages
  },
  methods:{
    changeLang(lang){
      this.$i18n.locale = lang
      localStorage.setItem("lang",lang)
      this.$emit('langChanged');
    },
  }
}
</script>