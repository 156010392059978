import filter from './filter'
import chain from './chain'
import eventbus from './eventbus'
import ui from './ui'
import api from './api'
import market from './market'

const plugins = [
    filter,
    chain,
    eventbus,
    ui,
    api,
    market
]

export default {
  install: function(Vue, options) {
    plugins.forEach(plugin => {
        Vue.use(plugin);
    });
  }
}