<template>
  <v-dialog 
    v-model="dialog" 
    max-width="400" 
    overlay-opacity="0.8"
    persistent
    content-class="dialog-bg rounded-lg ba">
    <v-responsive :aspect-ratio="4/4">
      <v-card flat 
        color="transparent"
        class="rounded-lg pa-1 d-flex flex-column justify-center align-center" 
        style="height:100%;">
        <!-- <v-hover v-slot="{ hover }">
          <img src="../assets/img/common/logo2.png" 
            class="pointer" 
            :class="hover?'rotate':''"
            style="width:80px;"/>
        </v-hover>
        <div class="text-h5 font-weight-bold">DIPX</div>
        <img src="../assets/img/common/coming-soon.svg" class="mt-5" style="width:150px;"/> -->
        <img src="../assets/img/common/maintenance.jpg" class="mt-5" style="width:100%;" />
      </v-card>
    </v-responsive>
  </v-dialog>
</template>

<script>
export default {
  data:()=>({
    dialog: true
  })
}
</script>