<template>
  <v-list color="transparent" dense flat
    :class="$vuetify.breakpoint.mobile?'':'rounded-lg'">

    <div class="d-flex flex-row align-center px-3" v-if="!$vuetify.breakpoint.mobile">
      <v-subheader class="px-0">{{$t("setting.layout.title")}}</v-subheader>
      <v-spacer></v-spacer>
      <div class="d-flex flex-row align-center">
        <v-btn icon @click="changeLayout('Left')">
          <img width="26" height="26" src="../assets/img/common/layout-left.png" />
        </v-btn>
        <v-btn icon @click="changeLayout('Right')" class="ml-0">
          <img width="26" height="26" src="../assets/img/common/layout-right.png" />
        </v-btn>
      </div>
    </div>

    <!-- <v-subheader class="px-3">{{$t("setting.language")}}</v-subheader>
    <v-list-item-group>
      <v-list-item v-for="(lang,n) in langs" 
        :key="n"
        style="cursor:pointer;" class="px-3" @click="changeLang(lang.value)">
        <v-list-item-content>
          <v-list-item-title>{{lang.name}}</v-list-item-title>
        </v-list-item-content>
        <v-icon small v-if="$i18n.locale==lang.value">mdi-check</v-icon>
      </v-list-item>
    </v-list-item-group> -->
  </v-list>
</template>

<script>

export default {
  data:()=>({
    langs: [],
    themes: [
      {value: 'Dark', name: 'Dark', icon: require('../assets/img/common/theme-dark.svg')},
      {value: 'Light', name: 'Light', icon: require('../assets/img/common/theme-light.svg')},
      {value: 'Auto', name: 'Auto'},
    ]
  }),
  mounted(){
    this.langs = this.$store.getters.config.app.languages
  },
  methods:{
    changeLayout(layout){
      localStorage.setItem("layout",layout)
      this.$bus.$emit("layoutChanged", layout)
    },
    changeTheme(theme){

    },
    changeLang(lang){
      this.$i18n.locale = lang
      localStorage.setItem("lang",lang)
    },
  }
}
</script>