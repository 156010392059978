<template>
  <v-footer color="transparent" style="border-top: 0px solid #ffffff14;">
    <div v-if="$vuetify.breakpoint.mobile" class="d-flex flex-column align-center py-8" style="width:100%;">
      <router-link class="" :to="{name:'Home'}">
        <img style="height:30px;" src="../assets/img/common/logo-color.svg" />
      </router-link>
      <div class="d-flex flex-row mt-6 align-center justify-space-around" style="max-width:450px;width:100%;">
        <a class="social" target="_blank" 
          v-for="(item,n) in socials"
          :key="n"
          :href="item.link">
          <inline-svg :src="item.icon" class="icon-hover d-block"></inline-svg>
        </a>
      </div>
    </div>
    <v-container v-else  class="d-flex flex-row align-center justify-center py-5" style="max-width:1440px;">
      <router-link class="d-flex flex-row align-center" :to="{name:'Home'}">
        <img style="height:30px;" src="../assets/img/common/logo-color.svg" />
      </router-link>
      <div class="d-flex flex-row align-center ml-5" style="">
        <a class="social ml-8" 
          target="_blank" 
          v-for="(item,n) in socials"
          :key="n"
          :href="item.link">
          
          <inline-svg :src="item.icon" class="icon-hover d-block"></inline-svg>
        </a>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data:()=>({
    socials: [
      {
        name: 'Twitter',
        link: 'https://twitter.com/dipxfinance',
        icon: require('../assets/img/footer/ic_twitter.svg')
      },
      {
        name: 'Github',
        link: 'https://github.com/DipxLabs',
        icon: require('../assets/img/footer/ic_github.svg')
      },
      {
        name: 'Medium',
        link: 'https://medium.com/@dipxfinance',
        icon: require('../assets/img/footer/ic_medium.svg')
      },
      {
        name: 'Discord',
        link: 'https://discord.gg/69SRBsG7ZA',
        icon: require('../assets/img/footer/ic_discord.svg')
      },
      {
        name: 'Docs',
        link: 'https://docs.dipx.io',
        icon: require('../assets/img/footer/ic_docs.svg')
      }
    ],
    twitter: 'https://twitter.com/dipxfinance',
    github: 'https://github.com/DipxLabs',
    medium: 'https://medium.com/@dipxfinance',
    discord: 'https://discord.gg/69SRBsG7ZA',
    docs: 'https://docs.dipx.io',
  })
}
</script>

<style scoped>
.social img{
  display: block;
}
.social:hover img{
  filter: brightness(0) invert(1);
}
.link:hover{
  color: #fff!important;
}
</style>