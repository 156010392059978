<template>
  <div class="dialog-bg"
    :style="!$vuetify.breakpoint.mobile?'min-width:380px;':''"
    :class="$vuetify.breakpoint.mobile?'bt':'ba'">
    <v-list  class="px-0"
      color="transparent">
      <v-subheader class="plain-text">Select a network</v-subheader>
      <v-list-item v-for="(item,n) in networks" 
        :key="n" 
        @click="changeNetwork(item)"
        class="py-0">
        <v-list-item-avatar class="mr-3" :size="$vuetify.breakpoint.mobile?30:20" tile>
          <v-avatar size="20" tile>
            <img :src="item.icon">
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-1 d-flex flex-row align-center">
            <span class="font-weight-bold">{{item.name}}</span>
            <div class="px-2 py-1 ml-2 mr-6 rounded cyan text-caption font-weight-bold" v-if="item.isTest" style="line-height: 1;">TESTNET</div>
          </v-list-item-title>
          
        </v-list-item-content>
        <div v-if="$store.state.chainId==item.chainId" class="orange rounded-circle pa-1"></div>
        <!-- <v-icon small v-if="$store.state.chainId==item.chainId">mdi-check</v-icon> -->
      </v-list-item>
    </v-list>
    
  </div>
</template>

<script>
const { ethers } = require("ethers");

export default {
  data:() => ({
    networks: [],
  }),
  mounted(){
    this.networks = this.$store.getters.config.app.networks
  },
  methods:{
    changeNetwork(network){
      this.$ui.showLoading({
        title:this.$t("app.changeNetwork"),
        msg:this.$t("app.waitingChangeNetwork"),
        persistent:true
      })
      this.$chain.addChain({
        chainId: ethers.utils.hexValue(
          network.chainId
        ),
        rpcUrls: [network.rpc],
        chainName: network.name,
        nativeCurrency: {
          name: network.nativeCurrency.name,
          decimals: network.nativeCurrency.decimals,
          symbol: network.nativeCurrency.symbol,
        },
        blockExplorerUrls: [network.blockExplorerUrls],
      }).then(() => {
        window.location.reload();
      }).catch(error => {
        this.$ui.showError({
          error: error,
          msg: this.$t("app.changeNetworkFail")
        })
      }).finally(()=>{
        this.$ui.hideLoading()
      })
      
      // this.$chain.switchChain(ethers.utils.hexValue(chainId));
    }
  }
}
</script>