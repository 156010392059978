<template>
  <v-dialog v-model="dialog" max-width="420" 
    @input="inputChange" 
    persistent
    overlay-opacity="0.8"
    content-class="dialog-bg  rounded-xl">
    <v-card class="" color="transparent">
      <v-responsive :aspect-ratio="1/1" content-class="d-flex flex-column">
        <v-card-title>
          <span>{{$t('wallet.connectAWallet')}}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close" class="mr-n2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="d-flex flex-column flex-grow-1 justify-center">
          <v-spacer></v-spacer>
          <v-hover v-slot="{ hover }">
            <img src="../assets/img/common/logo.svg"
              class="mx-auto"
              :style="$vuetify.breakpoint.mobile?'width:40px':'width:60px;'"
              :class="hover?'rotate':''"/>
          </v-hover>
          <v-spacer></v-spacer>
          <div @click="connectMetamask" 
            class="d-flex flex-row align-center pa-4 rounded-lg container-secondary-bg" 
            style="cursor:pointer;">
            <img style="width:28px;" src="../assets/img/common/metamask.png" />
            <span class="font-weight-bold text-subtitle-1 ml-3">MetaMask</span>
            <v-spacer></v-spacer>
            <v-progress-circular
              v-if="isLoading && connectType=='metamask'"
              :size="20"
              :width="2"
              indeterminate
              color="#895ff1"
            ></v-progress-circular>
          </div>
          <!-- <div class="d-flex flex-row align-center pa-4 rounded-lg mt-3 connect" 
            style="cursor:pointer;" @click="connectWalletConnect">
            <img style="width:28px;" src="../assets/img/common/walletConnectIcon.svg" />
            <span class="font-weight-bold text-subtitle-1 ml-3">WalletConnect</span>
          </div> -->
          <v-spacer class=""></v-spacer>
          <v-spacer class=""></v-spacer>
          <div class="px-0">
            <span>By connecting a wallet, you agree to DIPX Labs' </span>
            <a href="https://docs.dipx.io/terms-and-conditions" target="_blank">Terms of Service</a>
          </div>
        </v-card-text>
      </v-responsive>
    </v-card>
  </v-dialog>
</template>

<script>
import utils from '../util/utils'
import config from '../config'
const { ethers } = require("ethers");
// import WalletConnectProvider from "@walletconnect/web3-provider";
// const provider = new WalletConnectProvider({
//   rpc: {
//     97: config.chain.rpc
//   },
//   // uri: "wc:8a5e5bdc-a0e4-47...TJRNmhWJmoxdFo6UDk2WlhaOyQ5N0U=",
//   // // // bridge: "https://bridge.myhostedserver.com",
//   // qrcodeModalOptions: {
//   //   mobileLinks: ["metamask", "trust"]
//   // }
// });
// const chain = new ethers.providers.Web3Provider(provider, "any")
// provider.on("connect", async () => {
//   const account = await chain.getSigner().getAddress()
//   alert(account)
// });
// provider.on("disconnect", async () => {
//   alert("wallet diconnected");
// });

export default {
  components:{
  },
  props:[
    "value",
  ],
  data(){
    return {
      dialog: false,
      isLoading: false,
      connectType: ''
    }
  },
  watch:{
    value(newVal){
      if(newVal!=this.dialog){
        this.isLoading = false
      }
      this.dialog = newVal;
    }
  },
  methods:{
    close(){
      this.inputChange(false)
    },
    inputChange(opened){
      this.$emit('input',opened);
    },
    async connectWalletConnect(){
      // await provider.enable();
    },
    connectMetamask(){
      if (!utils.isMetamaskInstalled()) {
        this.$ui.showToast({msg: 'MetaMask not installed',color:'#ff0000'})
        return
      }
      this.connectType = 'metamask'
      this.isLoading = true
      this.$chain.requestAccount().then(accounts => {
        if(accounts.length>0){
          this.account = accounts[0]
          this.$bus.$emit("walletConnect", accounts[0])
        }
      }).then(()=>{
        this.close()
      }).catch(error =>{
        this.$ui.showError({
          error: error,
          msg: 'Connect Wallet Failed'
        })
      }).finally(() => {
        this.isLoading = false
      })

      // this.$chain.getNetwork().then(network => {
      //   if(network.chainId != config.chain.chainId){
      //     this.$chain.switchChain(ethers.utils.hexValue(config.chain.chainId));
      //     return;
      //   }
      //   this.$chain.requestAccount().then(accounts => {
      //     if(accounts.length>0){
      //       this.account = accounts[0]
      //       this.$bus.$emit("walletConnect", this.account)
      //     }
      //   })
      // }).then(()=>{
      //   this.close()
      // })
    }
  }
}
</script>

<style scoped>
.connect{
  background: rgb(41, 50, 73);
  cursor: pointer;
}
.connect:hover{
  background: rgba(173, 188, 255, 0.24);
}
</style>