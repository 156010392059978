import request from "../util/request"
import store from '../store'
import configs from '../config'

let apiInsts = {}

const spotAxios = request.createInst({
  timeout: 1000*15,
  baseURL: "https://api.dipx.io/spot"
})
// const apiAxios = request.createInst({
//   timeout: 1000*15,
//   baseURL: "https://api.dipx.io/optimism"
// })

function getApi() {
  const chainId = store.state.chainId
  if(apiInsts[chainId]){
    return apiInsts[chainId]
  }
  const config = store.getters.config
  const apiAxios = request.createInst({
    timeout: 1000*15,
    baseURL: config.api.apiServer
  })
  apiInsts[chainId] = apiAxios
  return apiAxios
}


const api = {
  syncTxEvent(txhash){
    return getApi().post('/event/tx',{hash:txhash})
  },
  feeData(){
    return getApi().get("/chain/feeData")
  },
  getDlpCollaterals(){
    return getApi().get("/dlp/collaterals")
  },
  getTokens({page,size}){
    return getApi().get("/token/list",{page,size})
  },
  getPools({page,size}){
    return getApi().get("/pool/list",{page,size})
  },
  getLiquidityList({to,page,size}){
    return getApi().get("/liquidity/list",{to,page,size})
  },
  getPositionList({account,page,size,index,collateral}){
    return getApi().get("/position/list",{account,page,size,index,collateral})
  },
  getTradeList({account,page,size,index,collateral}){
    return getApi().get("/trade/list",{account,page,size,index,collateral})
  },
  getOrderList({account,page,size,index,collateral}){
    return getApi().get("/order/list",{account,page,size,index,collateral})
  },
  uiKlines({symbol,interval,limit,startTime,endTime}){
    return spotAxios.get("/k/uiKlines",{symbol,interval,limit,startTime,endTime})
  },
  ticker24hr(symbol){
    return spotAxios.get("/k/ticker",{symbol})
  },
  prices(){
    return getApi().get("/prices")
  },
  multicall(calls,disableCache){
    return getApi().post(`/chain/multicall${disableCache?'?disableCache=1':''}`,calls)
  },
  getUserAffiliates({account,page,size}){
    return getApi().get("/user/affiliate/list",{account,page,size})
  },
  getAirdropInfo({account}){
    return getApi().get("/airdrop/sign",{account})
  }
}

export default {
  install: function(Vue, options) {
    Vue.prototype.$api = api;
  },
};