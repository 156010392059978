export default {
	common:{
		approve: '인정 받은',
		needApprove: '지갑에서 인증을 확인하세요',
		confirm: '확인하다',
		needConfirm: '확인 대기 중',
		longOrder: 'Long',
		shortOrder: 'Short',
		txConfirmError: '거래 실패',
		contract: 'Contract',
		totalSupply: 'Total Supply',
		tokenSupply: 'Token Supply',
		unrealisedProfit: 'Unrealised Profit',
		unrealisedLoss: 'Unrealised Loss',
		price: 'Price',
		name: 'Name',
		marketCap: 'Market Cap',
		sevenDaysVolume: '7Days Volume',
		sevenDaysFees: '7Days Fees',
		sevenDaysAverageSupply: '7Days Average Supply',
		sevenDaysAverageFee: '7Days Average Fee',
		apy: 'APY',
		buy: 'Buy',
		token: 'Token',
		copyAddress: 'Copy address',
		copied: 'Copied',
		balance: 'Balance',
		connectWallet: 'Connect wallet'
	},
	wallet:{
		connect: 'Connect',
		connectAWallet: 'Connect a wallet',
	},
	dlp:{
		tokenInPool: 'Pool',
		weight: 'Weight'
	},
	dipx:{
		totalStaked: 'Total Staked'
	},
	airdrop:{
		claim: '에어드롭 받기',
		claimFail: '에어드랍 신청 실패',
	},
	tx:{
		addLiquidity: 'Pay {0} {1} -> buy {2}',
		removeLiquidity: 'Sell {0} {1}',
		increasePosition: 'Increase {0} {1}',
		decreasePosition: 'Decrease {0} {1}',
		increaseCollateral: 'Increase collateral in {0} {1}',
		decreaseCollateral: 'Decrease collateral in {0} {1}',
		createIncreaseOrder: 'Create increase {0} {1} order ',
		createDecreaseOrder: 'Create decrease {0} {1} order ',
		cancelIncreaseOrder: 'Cancel increase {0} {1} order ',
		cancelDecreaseOrder: 'Cancel decrease {0} {1} order ',
		airdropClaim: 'Airdrop claim'
	},
	app:{
		changeNetwork: "스위치 네트워크",
		changeNetworkFail: "네트워크 전환 실패",
		waitingChangeNetwork: "스위칭 네트워크"
	},
	setting:{
		title: '환경 설정',
		themeSetting: '웹사이트 테마',
		languageSetting: '언어를 선택하세요',
		layoutSetting: '레이아웃 설정',
		language: {
			title: '언어'
		},
		theme:{
			title: '주제',
			dark: '어두운 테마',
			light: '밝은 테마',
			auto: '자동적 인'
		},
		layout: {
			title: '공들여 나열한 것',
			left: '왼쪽',
			right: '오른쪽'
		},
	},
	header: {
		home: '첫 장',
		dashboard: '대시 보드',
		pool: '유동성',
		trade: '거래',
		referral: '홍보',
		airdrop: '공중 투하',
		faucet: 'Faucet',
		earn: '벌다',
		rank: '순위'
	},
	bottomBar: {
		home: '첫 장',
		dashboard: '대시 보드',
		pool: '유동성',
		trade: '거래',
		earn: '벌다',
		referral: '홍보',
		faucet: 'Faucet',
		test: '시험',
		rank: '순위'
	},
	pool:{
		newPool: 'New Pool',
		pools: 'Pools',
		add:{
			needApprove: '인증 필요',
			addLiquidity: '유동성을 더하다',
			addLiquidityDesc: '유동성 추가 대기 중',
			success: '유동성이 성공적으로 추가되었습니다',
			fail: '유동성 추가 실패',
			selectToken: 'Select Token',
			depositAmount: 'Deposit Amounts',
			liquidityOut: 'Liquidity Amounts Out',
			poolInfo: 'Pool Information',
			poolName: 'Name',
			contract: 'Contract',
			token: 'Token',
			totalSupply: 'Total Supply',
			tokenSupply: 'Token Supply',
			unrealisedProfit: 'Unrealised Profit',
			unrealisedLoss: 'Unrealised Loss',
			price: 'Price',
			tokenInPool: 'Token In Pool',
			balance: 'Balance',
			tokenInfo: 'Token Information',
			tokenName: 'Name',
			confirm: 'Add Liquidity'
		},
		remove:{
			needApprove: '인증 필요',
			removeLiquidity: '유동성을 제거하다',
			removeLiquidityDesc: '유동성 제거를 기다리는 중',
			success: '유동성이 제거되었습니다',
			fail: '유동성 제거 실패',
			selectPool: 'Select Pool',
			amounts: 'Amounts',
			tokenOut: 'Token Out',
			tokenAmountsOut: 'Token Amounts Out',
			poolInfo: 'Pool Information',
			poolName: 'Name',
			contract: 'Contract',
			token: 'Token',
			totalSupply: 'Total Supply',
			price: 'Price',
			tokenInfo: 'Token Information',
			tokenInPool: 'Token In Pool',
			balance: 'Balance',
			tokenName: 'Name',
			confirm: 'Remove Liquidity',
			tokenSupply: 'Token Supply',
			unrealisedProfit: 'Unrealised Profit',
			unrealisedLoss: 'Unrealised Loss',
		},
		select:{
			notPoolAddress: '이 통화는 유동성 풀에 없습니다. 먼저 이 통화의 유동성을 추가하세요'
		}
	},
	trade:{
		success: '성공적인 거래',
		fail: "거래 실패",
		needApprove: '지갑에서 인증을 확인하세요',
		positions: 'Positions',
		orders: 'Orders',
		logs: 'Logs',
		all: 'All',
		showAll: 'Show All',
		priceType: {
			market: 'Market',
			limit: 'Limit',
			trigger: 'Trigger'
		},
		limit:{
			longShouldLower: '긴 주문에 대한 지정가 주문은 현재 가격보다 낮아야 합니다',
			shortShouldHigher: '짧은 주문에 대한 지정가 주문은 현재 가격보다 높아야 합니다',
			orderCreateSuccess: '주문이 성공적으로 생성됨'
		},
		collateral:{
			depositSuccess: '증거금 입금 성공',
			depositFail: '증거금 입금 실패',
			withdrawSuccess: '마진 인출 성공',
			withdrawFail: '마진 출금 실패'
		},
		actions:{
			IncreasePosition: 'Increase {0} {1}, size:+{2}, collateral:+{3} {4}, fee:{7} {4}, {5} Price:{6} USD',
			DecreasePosition: 'Decrease {0} {1}, size:-{2}, collateral:-{3} {4}, fee:{7} {4}, {5} Price:{6} USD',
			LiquidatePosition: 'Liquidate {0} {1}, size:-{2}, collateral:-{3} {4}, fee:{7} {4}, {5} Price:{6} USD',
			CreateIncreaseOrder: 'Create Order:Increase {0} {1} +{2} {3}, Price:{4}{5} USD',
			CreateDecreaseOrder: 'Create Order:Decrease {0} {1} -{2} {3}, Price:{4}{5} USD',
			CancelIncreaseOrder: 'Cancel Order:Increase {0} {1} +{2} {3}, Price:{4}{5} USD',
			CancelDecreaseOrder: 'Cancel Order:Decrease {0} {1} -{2} {3}, Price:{4}{5} USD',
			UpdateIncreaseOrder: 'Update Order:Increase {0} {1} +{2} {3}, Price:{4}{5} USD',
			UpdateDecreaseOrder: 'Update Order:Decrease {0} {1} -{2} {3}, Price:{4}{5} USD',
			ExecuteIncreaseOrder: 'Execute Order:Increase {0} {1} +{2} {3}, Price:{4} USD',
			ExecuteDecreaseOrder: 'Execute Order:Decrease {0} {1} -{2} {3}, Price:{4} USD',
		}
	},
	order:{
		action:{
			increase: 'Increase',
			decrease: 'Decrease'
		},
		cancelSuccess: '지정가 주문이 성공적으로 취소됨',
		cancelFail: '지정가 주문 취소 실패',
		createSuccess: '지정가 주문이 성공적으로 생성됨'
	},
	referral:{
		setReferrer: '추천자 설정',
		setReferrerInfo: '지갑에서 확인해주세요',
		setReferrerFail: '리퍼러 설정 실패',
		setReferrerSuccess: '추천인이 성공적으로 설정되었습니다'
	},
	test: {
		faucet: {
			mint: 'Mint',
			mintInfo: 'Please confirm in your wallet',
			mintSuccess: 'Mint Succeed!',
			mintFail: 'Mint Failed'
		}
	},
	earn:{
		vest:{
			title: '귀속 veDIPX',
			subtitle: 'DIPX에 속하도록 veDIPX를 잠그기로 선택하면 잠긴 veDIPX는 6개월 이내에 선형적으로 DIPX에 귀속됩니다',
			balance: '균형',
			activeVesting: '귀속',
			active: '귀속',
			claimable: '사용 가능',
			claim: '받다',
			lockToVest: '잠금 veDIPX',
			confirm: '확인하다',
			unlockVesting: 'veDIPX 잠금 해제',
			ruleTitle: '규칙',
			rules: [
				"1.veDIPX는 언제든지 DIPX에 기인할 수 있습니다",
				"2.DIPX에 속하도록 veDIPX를 잠그기로 선택한 후 잠긴 veDIPX는 6개월 이내에 선형적으로 DIPX에 귀속됩니다",
				"3.veDIPX 잠금을 선택한 후에는 언제든지 소유하지 않은 나머지 veDIPX를 잠금 해제할 수 있습니다",
				"4.veDIPX를 얻는 방법에는 DIPX에서 veDIPX로 전환, 유동성 채굴 보상, 거래 채굴 보상 등이 포함됩니다"
			],
			lock: '잠금',
			lockDesc: '',
			lockError: '잠금 실패',
			unlock: '터놓다',
			unlockDesc: '',
			unlockError: '잠금 해제 실패',
			claimDesc: '',
			claimError: '수신 실패'
		},
		stake:{
			title: '유동성 채굴',
			cumulativeReward: '공동 자금',
			estimatedReward: '예상 가용성',
			updateAt: '에 업데이트됨',
			yourBalance: '당신의 잔액',
			yourStaked: '너는 맹세했다',
			totalSupply: '총 공급량',
			totalStaked: '총 후원 금액',
			todayReward: '오늘 총 보상',
			ruleTitle: '규칙',
			rules: [
				"1.시스템은 유동성 채굴에 대한 보상으로 매일 veDIPX를 분배하고 보상은 시간에 따라 선형적으로 보상 풀로 방출됩니다",
				"2.DLP를 스테이킹하여 유동성 채굴 보상 획득",
				"3.유동성 채굴 보상은 DLP를 약속한 기간에 따라 결정됩니다/数量/占比来确定",
				"4.약속한 DLP는 언제든지 상환할 수 있으며 상환 후에는 더 이상 유동성 채굴 보상을 받을 수 없습니다",
				"5.플랫폼의 일일 유동성 채굴 총 보상량은 상황에 따라 조정될 수 있습니다"
			],
			balance: '균형',
			staked: '맹세하다',
			stakeToEarn: '서약 마이닝',
			stake: '약속',
			stakeDesc: '',
			stakeError: '서약 실패',
			unstake: '석방 서약',
			unstakeDesc: '',
			unstakeError: '스테이킹 해제 실패',
			claim: '받다',
			claimDesc: '',
			claimError: '수신 실패',
			refresh: '업데이트 보상',
			refreshDesc: '',
			refreshError: '보상을 업데이트하지 못했습니다',
		},
		trade:{
			title: '트랜잭션 마이닝',
			tips: '数据从2023-04-23开始计算',
			yourTradingVolume: '당신의 거래량',
			yourTodayVolume: '오늘의 거래량',
			estimatedClaimable: '예상 가용성',
			cumulative: '공동 자금',
			claim: '받다',
			claimError: 'Claim Error',
			claimDesc: '',
			totalTradingVolume: '총 거래량',
			todayVolume: '오늘의 거래량',
			rewardPerDay: '오늘 보상',
			totalCumulativeReward: '누적 총 보상',
			ruleTitle: '규칙',
			rules: [
				"1.당일 트랜잭션 마이닝 보상으로 매일 일정량의 veDIPX가 발행됩니다",
				"2.사용자의 트랜잭션 마이닝 보상은 당일 DLP 트랜잭션 볼륨의 비율에 따라 발행됩니다",
				"3.사용자는 둘째 날에 전날의 트랜잭션 마이닝 보상을 청구할 수 있습니다",
			]
		}
	}
}