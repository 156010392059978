import chainConfigs from "./chainConfig"
import apiConfigs from './apiConfig'
import appConfig from './appConfig'

function get(chainId) {
  return {
    app: appConfig,
    chain: chainConfigs[chainId+""],
    api: apiConfigs[chainId+""]
  }
}

export default {
  get,
}
