import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n/i18n';
import plugins from '@/plugins'
import VueClipboard from 'vue-clipboard2'
import './assets/css/common.scss'
import Empty from './components/Empty.vue'
import VueGtag from 'vue-gtag'
import { ethers } from 'ethers'
import utils from './util/utils'
import InlineSvg from 'vue-inline-svg';
import {
  // Directives
  VTooltip,
  VClosePopper,
  // Components
  Dropdown,
  Tooltip,
  Menu
} from 'floating-vue'
import 'floating-vue/dist/style.css'

Vue.config.productionTip = false

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popper', VClosePopper)

Vue.component('Dropdown', Dropdown)
Vue.component('Tooltip', Tooltip)
Vue.component('Menu', Menu)

Vue.use(plugins)
Vue.use(VueClipboard)
Vue.component('inline-svg', InlineSvg);
Vue.component("empty",Empty)
Vue.use(VueGtag, {
  config: { id: "G-QPGMSKMQGD" } 
}, router);

async function initConfig() {
  if(utils.isMetamaskInstalled()){
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    const network = await provider.getNetwork()
    store.dispatch("networkChange",{
      chainId: network.chainId
    })
  }
}

async function initApp() {
  await initConfig()
  return new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: function (h) { return h(App) }
  }).$mount('#app')
}

initApp()
