import { ethers } from "ethers"

const appConfig =  {
  languages: [
    {name:'English',value:'en',icon:require("@/assets/img/common/en-US_icon.png")},
    {name:'中文',value:'zh',icon:require("@/assets/img/common/zh-CN_icon.png")},
    {name:'한국어',value:'kr',icon:require("@/assets/img/common/ko-KR_icon.png")},
  ],
  networks:[
    // {
    //   name: 'Localhost',
    //   icon:require("@/assets/img/common/localhost.png"),
    //   chainId: 1337,
    //   rpc: "http://localhost:8545",
    //   nativeCurrency: {
    //     address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    //     name: 'ETH',
    //     decimals: 18,
    //     symbol: 'ETH'
    //   },
    //   blockExplorerUrls: 'https://arbiscan.io/',
    // },
    {
      name: 'Arbitrum One',
      icon:require("@/assets/img/common/arbitrum.png"),
      chainId: 42161,
      rpc: "https://arb1.arbitrum.io/rpc",
      nativeCurrency: {
        address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        name: 'ETH',
        decimals: 18,
        symbol: 'ETH'
      },
      blockExplorerUrls: 'https://arbiscan.io/',
    },
  ],
  maxLeverage: 90,
  // gasFee: ethers.utils.parseEther("0.0001"),
  // executeFee: ethers.utils.parseEther("0.00015"), //限价交易的fee
  // positionFee: 0.001,   //0.1%
  // fundingFee: 0.00001,   //0.00001=0.001%/1h
}

export default appConfig