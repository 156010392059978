import { ethers } from "ethers"

const arbitrumData = require("./contract/contract-arbitrum.json")
const localhostData = require("./contract/contract-localhost.json")

function makeConfig(
  {chainId, network, rpc, nativeCurrency,blockExplorerUrls,contractData,eth,gasFee,executeFee,positionFee,fundingFee}
) {
  return {
    chainId: chainId,
    rpc: rpc,
    network: network,
    nativeCurrency: {
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      decimals: 18,
      ...nativeCurrency
    },
    blockExplorerUrls: blockExplorerUrls,
    indexTokens:[
      {name: 'ETH-PERP',value:'ETH',address: contractData.eth,indexSymbol:'ETHUSDT'},
      {name: 'BTC-PERP',value:'BTC',address: contractData.btc,indexSymbol:'BTCUSDT'}
    ],
    gasFee: gasFee,
    executeFee: executeFee,
    positionFee: positionFee,
    fundingFee: fundingFee,
    pyth:contractData.pyth,
    dlpCollaterals: contractData.dlpCollaterals,
    contractData: contractData,
    eth: {
      address: contractData.eth,
      decimals: 18,
      ...eth
    },
    dipx: {
      abi: require("./abi/DIPX.json"),
      address: contractData.dipx,
      name: "DIPX"
    },
    dlp: {
      abi: require("./abi/DLP.json"),
      address: contractData.dlp,
      decimals: 18,
      name: "DLP"
    },
    lpManager: {
      abi: require("./abi/LpManager.json"),
      address: contractData.lpManager
    },
    positionManager:{
      abi: require("./abi/PositionManager.json"),
      address: contractData.positionManager
    },
    router: {
      abi: require("./abi/Router.json"),
      address: contractData.router
    },
    handler: {
      abi: require("./abi/Handler.json"),
      address: contractData.handler
    },
    vaultPriceFeed: {
      abi: require("./abi/VaultPriceFeed.json"),
      address: contractData.vaultPriceFeed
    },
    orderBook:{
      abi: require("./abi/OrderBook.json"),
      address: contractData.orderBook
    },
    multicall:{
      abi: require("./abi/Multicall.json"),
      address: contractData.multicall
    },
    storage:{
      abi: require("./abi/DipxStorage.json"),
      address: contractData.storage
    },
    faucet: {
      abi: require("./abi/Faucet.json"),
      address: contractData.faucet
    },
    referral: {
      abi: require("./abi/Referral.json"),
      address: contractData.referral
    },
    tradingYield: {
      abi: require("./abi/TradingYield.json"),
      address: contractData.tradingYield
    },
    genesisPass: {
      abi: require("./abi/DIPXGenesisPass.json"),
      address: contractData.dipxGenesisPass
    },
    airdrop: {
      abi: require("./abi/Airdrop.json"),
      address: contractData.airdrop
    },
    veDIPX: {
      abi: require("./abi/veDIPX.json"),
      address: contractData.vedipx
    },
    vesting: {
      abi: require("./abi/Vesting.json"),
      address: contractData.vesting
    },
    dlpYield: {
      abi: require("./abi/StakingYield.json"),
      address: contractData.dlpStakingYield
    },
    tradingYield: {
      abi: require("./abi/TradingYield.json"),
      address: contractData.tradingYield
    },
    distributor: {
      abi: require("./abi/RewardDistributor.json"),
      address: contractData.distributor
    }
  }
}

const defaultConfig = makeConfig({
  chainId: 0, 
  network: '',
  rpc: "",
  nativeCurrency: {
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: 'ETH',
    decimals: 18,
    symbol: 'ETH'
  },
  blockExplorerUrls: '',
  contractData: {},
  eth: {},
  gasFee: ethers.utils.parseEther("0.0001"),
  executeFee: ethers.utils.parseEther("0.00015"), //限价交易的fee
  positionFee: 0.001,   //0.1%
  fundingFee: 0.00001,   //0.00001=0.001%/1h
})

const localhostConfig = makeConfig({
  chainId: 1337,
  network: 'Localhost',
  rpc: "http://localhost:8545",
  nativeCurrency: {
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: 'ETH',
    decimals: 18,
    symbol: 'ETH'
  },
  blockExplorerUrls: 'https://arbiscan.io',
  eth: {
    name: 'ETH',
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    decimals: 18,
  },
  contractData: localhostData,
  gasFee: ethers.utils.parseEther("0.0001"),
  executeFee: ethers.utils.parseEther("0.00015"), //限价交易的fee
  positionFee: 0.001,   //0.1%
  fundingFee: 0.00001,   //0.00001=0.001%/1h
})

const arbitrumConfig = makeConfig({
  chainId: 42161,
  network: 'Arbitrum One',
  rpc: "https://arb1.arbitrum.io/rpc",
  nativeCurrency: {
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: 'ETH',
    decimals: 18,
    symbol: 'ETH'
  },
  blockExplorerUrls: 'https://arbiscan.io',
  eth: {
    name: 'ETH',
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    decimals: 18,
  },
  contractData: arbitrumData,
  gasFee: ethers.utils.parseEther("0.0001"),
  executeFee: ethers.utils.parseEther("0.00015"), //限价交易的fee
  positionFee: 0.001,   //0.1%
  fundingFee: 0.00001,   //0.00001=0.001%/1h
})


export default {
  '0': defaultConfig,
  '1337': localhostConfig,
  '42161': arbitrumConfig
}