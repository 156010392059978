export default {
	common:{
		approve: 'Approve',
		needApprove: 'Please confirm the authorization in your wallet',
		confirm: 'Confirm',
		needConfirm: 'Waiting for confirm...',
		longOrder: 'Long',
		shortOrder: 'Short',
		txConfirmError: 'Transaction Error',
		contract: 'Contract',
		totalSupply: 'Total Supply',
		tokenSupply: 'Token Supply',
		unrealisedProfit: 'Unrealised Profit',
		unrealisedLoss: 'Unrealised Loss',
		price: 'Price',
		name: 'Name',
		marketCap: 'Market Cap',
		sevenDaysVolume: '7Days Volume',
		sevenDaysFees: '7Days Fees',
		sevenDaysAverageSupply: '7Days Average Supply',
		sevenDaysAverageFee: '7Days Average Fee',
		apy: 'APY',
		buy: 'Buy',
		token: 'Token',
		copyAddress: 'Copy address',
		copied: 'Copied',
		balance: 'Balance',
		connectWallet: 'Connect wallet'
	},
	wallet:{
		connect: 'Connect',
		connectAWallet: 'Connect a wallet',
	},
	dlp:{
		tokenInPool: 'Pool',
		weight: 'Weight'
	},
	dipx:{
		totalStaked: 'Total Staked'
	},
	airdrop:{
		claim: 'Claim',
		claimFail: 'Claim failed',
	},
	tx:{
		addLiquidity: 'Pay {0} {1} -> buy {2}',
		removeLiquidity: 'Sell {0} {1}',
		increasePosition: 'Increase {0} {1}',
		decreasePosition: 'Decrease {0} {1}',
		increaseCollateral: 'Increase collateral in {0} {1}',
		decreaseCollateral: 'Decrease collateral in {0} {1}',
		createIncreaseOrder: 'Create increase {0} {1} order ',
		createDecreaseOrder: 'Create decrease {0} {1} order ',
		cancelIncreaseOrder: 'Cancel increase {0} {1} order ',
		cancelDecreaseOrder: 'Cancel decrease {0} {1} order ',
		airdropClaim: 'Airdrop claim'
	},
	app:{
		changeNetwork: "Switch network",
		changeNetworkFail: "Switch network failed",
		waitingChangeNetwork: "Waiting for switch network"
	},
	setting:{
		title: 'Global Setting',
		userfulLink: 'Useful Link',
		dashboard: 'Dashboard',
		dashboardDesc: 'System data',
		themeSetting: 'Theme for the web',
		languageSetting: 'Choose language',
		layoutSetting: 'Layout for UI',
		language: {
			title: 'Language'
		},
		theme:{
			title: 'Theme',
			dark: 'Dark',
			light: 'Light',
			auto: 'Automatic'
		},
		layout: {
			title: 'Layout',
			left: 'Left',
			right: 'Right'
		},
	},
	header: {
		home: 'Home',
		dashboard: 'Dashboard',
		pool: 'Pool',
		trade: 'Trade',
		referral: 'Referrals',
		airdrop: 'Airdrop',
		faucet: 'Faucet',
		earn: 'Earn',
		rank: 'Rank'
	},
	bottomBar: {
		home: 'Home',
		dashboard: 'Dashboard',
		pool: 'Pool',
		earn: 'Earn',
		trade: 'Trade',
		referral: 'Referrals',
		faucet: 'Faucet',
		test: 'Test',
		rank: 'Rank'
	},
	pool:{
		newPool: 'New Pool',
		pools: 'Pools',
		add:{
			needApprove: 'Before adding liquidity, you need to give the permission to the following contract to access your funds in your wallet.',
			addLiquidity: 'Add Liquidity',
			addLiquidityDesc: 'Waiting for adding liquidity...',
			success: 'Liquidity Injection Succeed!',
			fail: 'Liquidity Injection Failed!',
			selectToken: 'Select Token',
			depositAmount: 'Deposit Amounts',
			liquidityOut: 'Liquidity Amounts Out',
			poolInfo: 'Pool Information',
			poolName: 'Name',
			contract: 'Contract',
			token: 'Token',
			totalSupply: 'Total Supply',
			tokenSupply: 'Token Supply',
			unrealisedProfit: 'Unrealised Profit',
			unrealisedLoss: 'Unrealised Loss',
			price: 'Price',
			tokenInPool: 'Token In Pool',
			balance: 'Balance',
			tokenInfo: 'Token Information',
			tokenName: 'Name',
			confirm: 'Add Liquidity'
		},
		remove:{
			needApprove: 'Before removing liquidity, you need to give the permission to the following contract to access your funds in your wallet.',
			removeLiquidity: 'Remove Liquidity',
			removeLiquidityDesc: 'Waiting for removing liquidity...',
			success: 'Liquidity Removing Succeed!',
			fail: 'Liquidity Removing Failed!',
			selectPool: 'Select Pool',
			amounts: 'Amounts',
			tokenOut: 'Token Out',
			tokenAmountsOut: 'Token Amounts Out',
			poolInfo: 'Pool Information',
			poolName: 'Name',
			contract: 'Contract',
			token: 'Token',
			totalSupply: 'Total Supply',
			price: 'Price',
			tokenInfo: 'Token Information',
			tokenInPool: 'Token In Pool',
			balance: 'Balance',
			tokenName: 'Name',
			confirm: 'Remove Liquidity',
			tokenSupply: 'Token Supply',
			unrealisedProfit: 'Unrealised Profit',
			unrealisedLoss: 'Unrealised Loss',
		},
		select:{
			notPoolAddress: 'The address is not a pool, please add this token in AddLiquidity Page.'
		}
	},
	trade:{
		success: 'Transaction Successed',
		fail: "Transaction Failed",
		needApprove: 'Please confirm the authorization in your wallet',
		positions: 'Positions',
		orders: 'Orders',
		logs: 'Logs',
		all: 'All',
		showAll: 'Show All',
		priceType: {
			market: 'Market',
			limit: 'Limit',
			trigger: 'Trigger'
		},
		limit:{
			longShouldLower: 'Limited price of long should be less than the current price!',
			shortShouldHigher: 'Limitied price of short should be greater than the current price!',
			orderCreateSuccess: 'Order Created Successfully!'
		},
		collateral:{
			depositSuccess: 'Collateral Deposit Succeed!',
			depositFail: 'Collateral Deposit Failed!',
			withdrawSuccess: 'Collateral Withdraw Succeed!',
			withdrawFail: 'Collateral Withdraw Failed!'
		},
		actions:{
			IncreasePosition: 'Increase {0} {1}, size:+{2}, collateral:+{3} {4}, fee:{7} {4}, {5} Price:{6} USD',
			DecreasePosition: 'Decrease {0} {1}, size:-{2}, collateral:-{3} {4}, fee:{7} {4}, {5} Price:{6} USD',
			LiquidatePosition: 'Liquidate {0} {1}, size:-{2}, collateral:-{3} {4}, fee:{7} {4}, {5} Price:{6} USD',
			CreateIncreaseOrder: 'Create Order:Increase {0} {1} +{2} {3}, Price:{4}{5} USD',
			CreateDecreaseOrder: 'Create Order:Decrease {0} {1} -{2} {3}, Price:{4}{5} USD',
			CancelIncreaseOrder: 'Cancel Order:Increase {0} {1} +{2} {3}, Price:{4}{5} USD',
			CancelDecreaseOrder: 'Cancel Order:Decrease {0} {1} -{2} {3}, Price:{4}{5} USD',
			UpdateIncreaseOrder: 'Update Order:Increase {0} {1} +{2} {3}, Price:{4}{5} USD',
			UpdateDecreaseOrder: 'Update Order:Decrease {0} {1} -{2} {3}, Price:{4}{5} USD',
			ExecuteIncreaseOrder: 'Execute Order:Increase {0} {1} +{2} {3}, Price:{4} USD',
			ExecuteDecreaseOrder: 'Execute Order:Decrease {0} {1} -{2} {3}, Price:{4} USD',
		}
	},
	order:{
		action:{
			increase: 'Increase',
			decrease: 'Decrease'
		},
		cancelSuccess: 'Order Cancelled Successfully!',
		cancelFail: 'Order Cancelled Unsuccessfully!',
		createSuccess: 'Order Created Successfully!'
	},
	referral:{
		setReferrer: 'Set Referrer',
		setReferrerInfo: 'Please confirm in your wallet',
		setReferrerFail: 'Set Referrer Failed',
		setReferrerSuccess: 'Set Referrer Succeed'
	},
	test: {
		faucet: {
			mint: 'Mint',
			mintInfo: 'Please confirm in your wallet',
			mintSuccess: 'Mint Succeed!',
			mintFail: 'Mint Failed'
		}
	},
	earn:{
		vest:{
			title: 'Vest veDIPX',
			subtitle: 'Once you lock to vest DIPX, you will receive DIPX every block linearly in 6 months.',
			balance: 'Balance',
			activeVesting: 'Active Vesting',
			active: 'Active',
			claimable: 'Claimable',
			claim: 'Claim',
			lockToVest: 'Lock veDIPX to Vest',
			confirm: 'Confirm',
			unlockVesting: 'Unlock veDIPX',
			ruleTitle: 'Rules',
			rules: [
				"1.veDIPX can be vested into DIPX at any time",
				"2.Once veDIPX is locked to vest, DIPX will be linearly released every block over a 6-month period.",
				"3.veDIPX vesting can be interrupted and veDIPX can be unlocked at any time during the 6-month period.",
				"4.Ways to acquire veDIPX include: converting DIPX to veDIPX, liquidity mining rewards, transaction mining rewards."
			],
			lock: 'Lock',
			lockDesc: '',
			lockError: 'Vest Lock Error',
			unlock: 'Unlock',
			unlockDesc: '',
			unlockError: 'Vest Unlock Error',
			claimDesc: '',
			claimError: 'Vest Claim Error'
		},
		stake:{
			title: 'Liquidity Provider',
			cumulativeReward: 'Cumulative Reward',
			estimatedReward: 'Estimated Claimable Reward',
			updateAt: 'Updated at',
			yourBalance: 'Your Balance',
			yourStaked: 'Your Staked',
			totalSupply: 'Total Supply',
			totalStaked: 'Total Staked',
			todayReward: 'Today Reward',
			ruleTitle: 'Rules',
			rules: [
				"1.Rewarding veDIPX daily for liquidity mining, with rewards linearly released over time.",
				"2.Staking of DLP is required to participate in liquidity mining and receive rewards.",
				"3.Daily rewards are distributed based on the duration and amount of DLP staked.",
				"4.Staked DLP can be withdrawn at any time, but rewards will cease upon withdrawal.",
				"5.Daily rewards may be subject to adjustment."
			],
			balance: 'Balance',
			staked: 'Staked',
			stakeToEarn: 'Stake to earn',
			stake: 'Stake',
			stakeDesc: '',
			stakeError: 'Stake Error',
			unstake: 'Unstake',
			unstakeDesc: '',
			unstakeError: 'Unstake Error',
			claim: 'Claim',
			claimDesc: '',
			claimError: 'Claim Error',
			refresh: 'Update Reward',
			refreshDesc: '',
			refreshError: 'Update Reward Error',
		},
		trade:{
			title: 'Trader',
			tips: 'Data since 2023-04-23',
			yourTradingVolume: 'Your Trading Volume',
			yourTodayVolume: 'Your Today Volume',
			estimatedClaimable: 'Estimated Claimable',
			cumulative: 'Your Cumulative Reward',
			claim: 'Claim',
			claimError: 'Claim Error',
			claimDesc: '',
			totalTradingVolume: 'Total Trading Volume',
			todayVolume: 'Today Volume',
			rewardPerDay: 'Today Reward',
			totalCumulativeReward: 'Total Cumulative Reward',
			ruleTitle: 'Rules',
			rules: [
				"1.Rewarding veDIPX daily for trading mining, the number of rewards may be adjusted",
				"2.Daily rewards are distributed  based on the proportion of each participant‘s daily DLP trading volume to the total trading volume of the day.",
				"3.Rewards can be claimed on the next day.",
			]
		}
	}
}