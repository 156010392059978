import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: {name:'Dashboard'}
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard/Index')
  },
  {
    path: '/pool',
    name: 'Pool',
    component: () => import('../views/pool/Pool')
  },
  {
    path: '/pool/add',
    name: 'AddLiquidity',
    component: () => import('../views/pool/AddLiquidity')
  },
  {
    path: '/pool/remove',
    name: 'RemoveLiquidity',
    component: () => import('../views/pool/RemoveLiquidity')
  },
  {
    path: '/trade',
    name: 'Trade',
    component: () => import('../views/trade/Trade')
  },
  {
    path: '/referral',
    name: 'Referral',
    component: () => import('../views/referral/Referral')
  },
  {
    path: '/airdrop',
    name: 'Airdrop',
    component: () => import('../views/airdrop/Index')
  },
  {
    path: '/faucet',
    name: 'Faucet',
    component: () => import('../views/faucet/Faucet')
  },
  {
    path: '/earn',
    name: 'Earn',
    component: () => import('../views/earn/Index')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/error/404')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
})

let routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject){
  if(resolve && reject){
    routerPush.call(this,location, resolve, reject)
  }else{
    routerPush.call(this, location, ()=>{}, ()=>{})
  }
}

export default router
