<template>
  <div class="dialog-bg "
    style="min-width:320px;"
    :style="!$vuetify.breakpoint.mobile?'width:420px;':''"
    :class="$vuetify.breakpoint.mobile?'bt':'ba'">
    <div v-if="showGlobal" class="pa-4" style="height: 100%;">
      <div class="d-flex flex-row align-center">
        <span class="text-h6 font-weight-bold">{{$t('setting.title')}}</span>
        <v-spacer></v-spacer>
        <v-btn icon class="rounded-lg" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div style="overflow-y: scroll;height: 100%;">
        <v-row class="mt-3" dense>
          <v-col cols="6" >
            <div class="container-secondary-bg rounded pa-4 pointer" @click="showSetting('theme')">
              <div class="d-flex flex-row align-center">
                <inline-svg v-if="currentTheme.icon" :src="currentTheme.icon" width="24" class=""></inline-svg>
                <v-spacer></v-spacer>
                <v-icon class="plain-text">mdi-chevron-right</v-icon>
              </div>
              <div class="mt-6">
                <div class="font-weight-bold">{{$t(currentTheme.key)}}</div>
                <div class="plain-text text-body-2 mt-1">{{$t('setting.themeSetting')}}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" >
            <div class="container-secondary-bg rounded pa-4 pointer" @click="showSetting('lang')">
              <div class="d-flex flex-row align-center">
                <img :src="currentLang.icon" width="24">
                <v-spacer></v-spacer>
                <v-icon class="plain-text">mdi-chevron-right</v-icon>
              </div>
              <div class="mt-6">
                <div class="font-weight-bold">{{currentLang.name}}</div>
                <div class="plain-text text-body-2 mt-1">{{$t('setting.languageSetting')}}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" >
            <div class="container-secondary-bg rounded pa-4 pointer" @click="showSetting('layout')">
              <div class="d-flex flex-row align-center">
                <inline-svg v-if="currentLayout.icon" :src="currentLayout.icon" width="24" height="24" class=""></inline-svg>
                <v-spacer></v-spacer>
                <v-icon class="plain-text">mdi-chevron-right</v-icon>
              </div>
              <div class="mt-6">
                <div class="font-weight-bold">{{$t(currentLayout.key)}}</div>
                <div class="plain-text text-body-2 mt-1">{{$t('setting.layoutSetting')}}</div>
              </div>
            </div>
          </v-col>
        </v-row>

        <div class="mt-6" v-if="$vuetify.breakpoint.mobile">
          <div class="d-flex flex-row align-center">
            <span class="text-h6 font-weight-bold">{{$t('setting.userfulLink')}}</span>
          </div>
          <v-row class="mt-3" dense>
            <v-col cols="6" >
              <a href="https://dune.com/dipx/overview" class="d-block container-secondary-bg rounded pa-4 pointer">
                <div class="d-flex flex-row align-center">
                  <inline-svg class="icon-hover" :src="require('../assets/img/home/dashboard.svg')" width="24px" height="24px"></inline-svg>
                  <v-spacer></v-spacer>
                  <v-icon class="plain-text">mdi-chevron-right</v-icon>
                </div>
                <div class="mt-6">
                  <div class="font-weight-bold">{{$t('setting.dashboard')}}</div>
                  <div class="plain-text text-body-2 mt-1">{{$t('setting.dashboardDesc')}}</div>
                </div>
              </a>
            </v-col>
          </v-row>
        </div>

        <div class="d-flex flex-row mt-6 align-center justify-space-around px-4 py-4" style="width:100%;">
          <a class="social" target="_blank" 
            v-for="(item,n) in socials"
            :key="n"
            :href="item.link">
            <inline-svg :src="item.icon" class="icon-hover d-block"></inline-svg>
          </a>
        </div>
      </div>
      

    </div>
    <div v-else class="d-flex flex-column">
      <div v-if="showThemeSetting">
        <div class="d-flex flex-row align-center px-2 py-3">
          <v-btn icon @click="closeSetting" class="rounded-lg">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <span class="text-h6 font-weight-bold">{{$t("setting.theme.title")}}</span>
          <v-spacer></v-spacer>
          <v-btn icon disabled>
          </v-btn>
        </div>
        <v-divider class="mx-2"></v-divider>
        <v-list color="transparent" class="py-0" flat>
          <v-list-item-group>
            <v-list-item v-for="(theme,n) in themes" 
              :key="n"
              style="cursor:pointer;" class="px-6" @click="changeTheme(theme)">
              <v-list-item-icon class="mr-6">
                <inline-svg :src="theme.icon" width="24" class=""></inline-svg>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{$t(theme.key)}}</v-list-item-title>
              </v-list-item-content>
              <v-icon small v-if="currentTheme.value==theme.value">mdi-check</v-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>

      <div v-else-if="showLangSetting">
        <div class="d-flex flex-row align-center px-2 py-3">
          <v-btn icon @click="closeSetting" class="rounded-lg">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <span class="text-h6 font-weight-bold">{{$t("setting.language.title")}}</span>
          <v-spacer></v-spacer>
          <v-btn icon disabled>
          </v-btn>
        </div>
        <v-divider class="mx-2"></v-divider>
        <v-list color="transparent" class="py-0" flat>
          <v-list-item-group>
            <v-list-item v-for="(lang,n) in langs" 
              :key="n"
              style="cursor:pointer;" class="px-6" @click="changeLang(lang)">
              <v-list-item-icon class="mr-6">
                <img :src="lang.icon" width="24" class="">
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{lang.name}}</v-list-item-title>
              </v-list-item-content>
              <v-icon small v-if="$i18n.locale==lang.value">mdi-check</v-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>

      <div v-else-if="showLayoutSetting">
        <div class="d-flex flex-row align-center px-2 py-3">
          <v-btn icon @click="closeSetting" class="rounded-lg">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <span class="text-h6 font-weight-bold">{{$t("setting.layout.title")}}</span>
          <v-spacer></v-spacer>
          <v-btn icon disabled>
          </v-btn>
        </div>
        <v-divider class="mx-2"></v-divider>
        <v-list color="transparent" class="py-0" flat>
          <v-list-item-group>
            <v-list-item v-for="(layout,n) in layouts" 
              :key="n"
              style="cursor:pointer;" class="px-6" @click="changeLayout(layout)">
              <v-list-item-icon class="mr-6">
                <inline-svg :src="layout.icon" width="24" height="24" class=""></inline-svg>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{$t(layout.key)}}</v-list-item-title>
              </v-list-item-content>
              <v-icon small v-if="currentLayout.value==layout.value">mdi-check</v-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props:["show"],
  data: () => ({
    showGlobal: true,
    showThemeSetting: false,
    showLangSetting: false,
    showLayoutSetting: false,
    themes: [
      {key:'setting.theme.light', value:'light', icon: require("../assets/img/common/theme-light.svg")},
      {key:'setting.theme.dark', value:'dark', icon: require("../assets/img/common/theme-dark.svg")},
      {key:'setting.theme.auto', value:'auto', icon: require("../assets/img/common/theme-auto.svg")}
    ],
    currentTheme: {},
    langs: [],
    currentLang: {},
    layouts: [
      {key:'setting.layout.left', value:'Left', icon: require("../assets/img/common/layout-left.svg")},
      {key:'setting.layout.right', value:'Right', icon: require("../assets/img/common/layout-right.svg")}
    ],
    currentLayout: {},
    socials: [
      {
        name: 'Twitter',
        link: 'https://twitter.com/dipxfinance',
        icon: require('../assets/img/footer/ic_twitter.svg')
      },
      {
        name: 'Github',
        link: 'https://github.com/DipxLabs',
        icon: require('../assets/img/footer/ic_github.svg')
      },
      {
        name: 'Medium',
        link: 'https://medium.com/@dipxfinance',
        icon: require('../assets/img/footer/ic_medium.svg')
      },
      {
        name: 'Discord',
        link: 'https://discord.gg/69SRBsG7ZA',
        icon: require('../assets/img/footer/ic_discord.svg')
      },
      {
        name: 'Docs',
        link: 'https://docs.dipx.io',
        icon: require('../assets/img/footer/ic_docs.svg')
      }
    ]
  }),
  watch:{
    show(newVal, oldVal){
      if (newVal) {
        this.closeSetting()
      }
    }
  },
  mounted(){
    const themeValue = localStorage.getItem("theme") || 'auto'
    
    for (let i = 0; i < this.themes.length; i++) {
      if(this.themes[i].value == themeValue){
        this.currentTheme = this.themes[i]
      }
    }

    this.langs = this.$store.getters.config.app.languages
    console.log(this.$i18n.locale)
    for (let i = 0; i < this.langs.length; i++) {
      if(this.$i18n.locale == this.langs[i].value){
        this.currentLang = this.langs[i]
      }
    }

    const layoutValue = localStorage.getItem("layout") || 'Right'
    for (let i = 0; i < this.layouts.length; i++) {
      if(this.layouts[i].value == layoutValue){
        this.currentLayout = this.layouts[i]
      }
    }

  },
  methods:{
    close(){
      this.$emit("close")
    },
    closeSetting(){
      this.showGlobal = true
      this.showThemeSetting = false
      this.showLangSetting = false
      this.showLayoutSetting = false
    },
    showSetting(setting){
      this.showGlobal = false
      if(setting == 'theme'){
        this.showThemeSetting = true
      }else if(setting == 'lang'){
        this.showLangSetting = true
      }else if(setting == 'layout'){
        this.showLayoutSetting = true
      }
    },
    changeTheme(theme){
      this.currentTheme = theme
      if(theme.value == 'dark'){
        this.$vuetify.theme.dark = true
      }else if(theme.value == 'light'){
        this.$vuetify.theme.dark = false
      }else if(theme.value == 'auto'){
        const scheme = window.matchMedia('(prefers-color-scheme: dark)')
        this.$vuetify.theme.dark = scheme.matches
      }
      localStorage.setItem("theme",theme.value)
      this.closeSetting()
    },
    changeLang(lang){
      this.currentLang = lang
      this.$i18n.locale = lang.value
      localStorage.setItem("lang",lang.value)
      this.$emit('langChanged');
      this.closeSetting()
    },
    changeLayout(layout){
      this.currentLayout = layout
      localStorage.setItem("layout",layout.value)
      this.$bus.$emit("layoutChanged", layout.value)
      this.closeSetting()
    }
  }
  
}
</script>

<style scoped>
.social img{
  display: block;
}
.social:hover img{
  filter: brightness(0) invert(1);
}
.link:hover{
  color: #fff!important;
}
</style>