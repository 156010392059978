<template>
  <v-list v-if="pendingTxs.length>0 || txs.length>0" color="transparent" dense class="py-0">
    <v-subheader class="px-3 font-weight-bold" 
      v-if="pendingTxs.length>0">
      Pending
    </v-subheader>
    <v-list-item-group>
      <template v-for="(tx,n) in pendingTxs">
        <v-list-item
          :href="tx.txhash | explorer('tx')" target="_blank"
          style="cursor:pointer;">
          <v-list-item-avatar>
            <v-img :src="actionIcon(tx.action)" contain style="width:30px;height: 30px;"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle style="word-wrap: break-word;white-space: pre-wrap;">{{ parseTxInfo(tx)}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-progress-circular
              :size="15"
              :width="2"
              class="mx-2"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="n<pendingTxs.length-1" inset></v-divider>
      </template>
    </v-list-item-group>
    <v-subheader class="px-3 font-weight-bold"
      v-if="txs.length>0 && pendingTxs.length>0">History</v-subheader>
    <v-list-item-group v-if="txs.length>0">
      <template v-for="(tx,n) in txs">
        <v-list-item
          :href="tx.txhash | explorer('tx')" target="_blank"
          style="cursor:pointer;">
          <v-list-item-avatar>
            <v-img :src="actionIcon(tx.action)" contain style="width:24px;height: 24px;"></v-img>
          </v-list-item-avatar>
          <v-list-item-content >
            <v-list-item-subtitle style="word-wrap: break-word;white-space: pre-wrap;">{{ parseTxInfo(tx)}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="ml-2">
            <v-icon v-if="tx.status=='fail'" size="20" color="white">mdi-alert-outline</v-icon>
            <img v-else src="../assets/img/common/ic_link.svg"  style="width: 20px;filter: brightness(0) invert(1);"/>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="n<txs.length-1" inset></v-divider>
      </template>
      
    </v-list-item-group>
  </v-list>
  <div v-else class="d-flex flex-colume" style="height:100%;">
    <v-spacer></v-spacer>
    <empty
      message="Your transactions will appear here"
      class="py-6 plain-text">
    </empty>
    <v-spacer></v-spacer>
  </div>
</template>

<script>
import utils from "@/util/utils"
import { ethers } from "ethers"

export default {
  data: () => ({
    pendingTxs: [
    ],
    txs: [
    ]
  }),
  mounted(){
    if(this.$store.getters.isLogin){
      this.pendingTxs = this.$store.state.pendingTxs
      this.txs = this.$store.getters.userTxs.filter(tx => {
        for (let i = 0; i < this.pendingTxs.length; i++) {
          if(utils.addressEq(this.pendingTxs[i].txhash,tx.txhash)){
            return false
          }
        }
        return true
      })
    }
    this.$bus.$on("walletConnect", (account) => {
      this.txs = this.$store.getters.userTxs
      this.pendingTxs = []
    });

    this.$bus.$on("PendingTx", (tx) => {
      this.pendingTxs= [
        tx,
        ...this.pendingTxs.filter(item => utils.addressEq(item.txhash,tx.txhash))
      ]
    });
    this.$bus.$on("ClearTxs", (tx) => {
      this.txs = []
    });
    this.$bus.$on("ConfirmTx", ({txhash}) => {
      const itemIndex = this.pendingTxs.findIndex(tx => tx.txhash = txhash)
      if(itemIndex>=0){
        this.txs = [
          this.pendingTxs[itemIndex],
          ...this.txs
        ]
      }
      
      this.pendingTxs = this.pendingTxs.filter(tx => !utils.addressEq(tx.txhash,txhash))
    });
  },
  methods:{
    actionIcon(action){
      const icons = {
        "AddLiquidity": require('../assets/img/action/addLiquidity.svg'),
        "RemoveLiquidity": require('../assets/img/action/removeLiquidity.svg'),
        "IncreasePosition": require('../assets/img/action/increasePosition.svg'),
        "DecreasePosition": require('../assets/img/action/decreasePosition.svg'),
        "CreateIncreaseOrder": require('../assets/img/action/createIncOrder.svg'),
        "CreateDecreaseOrder": require('../assets/img/action/createDecOrder.svg'),
        "CancelIncreaseOrder": require('../assets/img/action/cancelOrder.svg'),
        "CancelDecreaseOrder": require('../assets/img/action/cancelOrder.svg'),
        "IncreaseCollateral": require('../assets/img/action/deposit.png'),
        "DecreaseCollateral": require('../assets/img/action/withdraw.png'),

        "AirdropClaim": require('../assets/img/action/unknown.svg'),
      }

      const unknown = require('../assets/img/action/unknown.svg')
      let icon = icons[action]
      if(!icon){
        icon = unknown
      }
      return icon
    },
    indexName(indexToken){
      let index = this.$store.getters.config.chain.indexTokens.find(item => utils.addressEq(item.address,indexToken))
      if(!index){
        return ''
      }
      return index.name
    },
    parseTxInfo(tx){
      const config = this.$store.getters.config
      if(tx.action == 'AddLiquidity'){
        const rowData = tx.rowData
        let poolName = ''
        if(utils.isDlp(rowData.targetPool)){
          poolName = config.chain.dlp.name
        }else{
          poolName = 'SLP-'+rowData.tokenName
        }
        const amountIn = utils.toFixed(ethers.utils.formatUnits(rowData.amountIn,rowData.decimals),4)
        return this.$t(`tx.addLiquidity`,[amountIn,rowData.tokenName,poolName])
      }else if(tx.action == 'RemoveLiquidity'){
        const rowData = tx.rowData
        const liquidityIn = utils.toFixed(ethers.utils.formatUnits(rowData.liquidityIn,rowData.decimals),4)
        let poolName = ''
        return this.$t(`tx.removeLiquidity`,[
            liquidityIn,
            rowData.pooName
          ])
      }else if(tx.action == 'IncreasePosition'){
        const rowData = tx.rowData
        let indexName = this.indexName(rowData.indexToken)
        let orderType = rowData.isLong?'Long':'Short'
        return this.$t(`tx.increasePosition`,[
            indexName,
            orderType
          ])
      }else if(tx.action == 'DecreasePosition'){
        const rowData = tx.rowData
        let indexName = this.indexName(rowData.indexToken)
        let orderType = rowData.isLong?'Long':'Short'
        return this.$t(`tx.decreasePosition`,[
            indexName,
            orderType
          ])
      }else if(tx.action == 'CreateIncreaseOrder'){
        const rowData = tx.rowData
        let indexName = this.indexName(rowData.indexToken)
        let orderType = rowData.isLong?'Long':'Short'
        return this.$t(`tx.createIncreaseOrder`,[
            indexName,
            orderType
          ])
      }else if(tx.action == 'CreateDecreaseOrder'){
        const rowData = tx.rowData
        let indexName = this.indexName(rowData.indexToken)
        let orderType = rowData.isLong?'Long':'Short'
        return this.$t(`tx.createDecreaseOrder`,[
            indexName,
            orderType
          ])
      }else if(tx.action == 'CancelIncreaseOrder'){
        const rowData = tx.rowData
        let indexName = this.indexName(rowData.indexToken)
        let orderType = rowData.isLong?'Long':'Short'
        return this.$t(`tx.cancelIncreaseOrder`,[
            indexName,
            orderType
          ])
      }else if(tx.action == 'CancelDecreaseOrder'){
        const rowData = tx.rowData
        let indexName = this.indexName(rowData.indexToken)
        let orderType = rowData.isLong?'Long':'Short'
        return this.$t(`tx.cancelDecreaseOrder`,[
            indexName,
            orderType
          ])
      }else if(tx.action == 'IncreaseCollateral'){
        const rowData = tx.rowData
        let indexName = this.indexName(rowData.indexToken)
        let orderType = rowData.isLong?'Long':'Short'
        return this.$t(`tx.increaseCollateral`,[
            indexName,
            orderType
          ])
      }else if(tx.action == 'DecreaseCollateral'){
        const rowData = tx.rowData
        let indexName = this.indexName(rowData.indexToken)
        let orderType = rowData.isLong?'Long':'Short'
        return this.$t(`tx.decreaseCollateral`,[
            indexName,
            orderType
          ])
      }else if(tx.action == 'AirdropClaim'){
        const rowData = tx.rowData
        return this.$t(`tx.airdropClaim`)
      }
      return tx.action
    }
  }
  
}
</script>