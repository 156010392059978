<template>
  <v-app-bar app 
    :elevation="elevation"
    flat 
    :height="$vuetify.breakpoint.mobile?56:78"
    class="px-0 app-bg">
    <router-link :to="{name:'Home'}">
      <img 
        class="d-block"
        src="../assets/img/common/logo-color.svg" 
        style="height:24px;"/>
    </router-link>
    <div v-if="!$vuetify.breakpoint.mobile" class="ml-10">
      <template v-for="(item,n) in navs" >
        <v-btn 
          v-if="item.to"
          :to="item.to" class="text-none" plain>
          {{ $t(item.name) }}
        </v-btn>
        <v-btn 
          v-else
          :href="item.link" target="_blank" class="text-none" plain>
          {{ $t(item.name) }}
        </v-btn>
      </template>
    </div>
    
    <v-spacer></v-spacer>
    <!-- <router-link 
      v-if="$vuetify.breakpoint.mobile"
      :to="{name:'Airdrop'}" 
      class="pa-2 mr-2 d-flex flex-row align-center font-weight-bold" 
      active-class="nav-active"
    >
      <v-icon color="#2f8af5" size="24">mdi-gift</v-icon>
    </router-link> -->
    <!-- <router-link 
      v-if="$vuetify.breakpoint.mobile"
      :to="{name:'Faucet'}" 
      class="pa-2 mr-2 d-flex flex-row align-center" 
      active-class="nav-active"
    >
      <img style="width:24px;height:24px;" src="../assets/img/navbar/faucet.svg"
        class="d-block">
    </router-link> -->
    <v-bottom-sheet v-if="$vuetify.breakpoint.mobile" v-model="networkSheet"
      scrollable
      overlay-opacity="0.8"
      content-class="bottom-sheet-content">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text class="text-none rounded-lg px-2" v-bind="attrs" v-on="on"
          :class="networkError?'primary-btn':''">
          <template v-if="!networkError">
            <v-img contain width="24" height="24" :src="currentNetwork?currentNetwork.icon:''"></v-img>
            <!-- <span class="ml-1 text-truncate" style="max-width:120px;">{{currentNetwork?currentNetwork.name:''}}</span> -->
          </template>
          <template v-else>
            <v-icon size="20">mdi-alert-outline</v-icon>
            <span class="ml-1">{{isWeb3Installed?"Unsupported":"NoMetaMask"}}</span>
          </template>
          <v-icon class="ml-0">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <network-card class="rounded-t-lg"></network-card>
    </v-bottom-sheet>
    <v-menu v-else offset-y left nudge-top="-10" min-width="250" content-class="rounded-lg">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text class="text-none rounded-lg px-2" v-bind="attrs" v-on="on"
          :class="networkError?'primary-btn':''">
          <template v-if="!networkError">
            <v-img contain width="24" height="24" :src="currentNetwork?currentNetwork.icon:''"></v-img>
            <span class="ml-1">{{currentNetwork?currentNetwork.name:''}}</span>
          </template>
          <template v-else>
            <v-icon size="20">mdi-alert-outline</v-icon>
            <span class="ml-1">{{isWeb3Installed?"Unsupported":"MetaMaskNotInstalled"}}</span>
          </template>
          <v-icon class="ml-1">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <network-card class="rounded-lg"></network-card>
    </v-menu>
    
    <div v-if="!account" class="rounded-pill d-flex flex-row align-center primary-btn ml-2"
      :class="$vuetify.breakpoint.mobile?'px-2':'px-2'">
      <v-btn class="text-none white--text" text :ripple="false" @click="showConnectDialog"
        :class="$vuetify.breakpoint.mobile?'px-1':'px-2'"
      >
        {{$t('wallet.connect')}}
      </v-btn>
      <!-- <div v-if="!$vuetify.breakpoint.mobile" 
        style="width:1px;height:15px;background: #ffffff34;" 
        class="mr-1">
      </div>
      <v-bottom-sheet v-if="$vuetify.breakpoint.mobile" v-model="connectSheet"
        scrollable
        overlay-opacity="0.8"
        content-class="bottom-sheet-content">
        <template v-slot:activator="{ on, attrs }">
          <v-icon style="cursor:pointer;" v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
        </template>
        <connect-card :show="connectSheet" :connect="showConnectDialog" class="rounded-t-lg"></connect-card>
      </v-bottom-sheet>
      <v-menu v-model="connectSheet" v-else offset-y left nudge-top="-20" nudge-right="8"
        :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-icon style="cursor:pointer;" v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
        </template>
        <connect-card :show="connectSheet" :connect="showConnectDialog" class="rounded-lg" style="min-width:300px;"></connect-card>
      </v-menu> -->
    </div>
    <template v-else>
      <v-bottom-sheet v-if="$vuetify.breakpoint.mobile" 
        v-model="userSheet"
        overlay-opacity="0.8"
        scrollable
        content-class="bottom-sheet-content">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small class="text-none px-0 ml-2" 
            v-bind="attrs" v-on="on"
            :icon="pendingTxs.length==0"
            :class="pendingTxs.length>0?'rounded-pill primary':'rounded-circle'">
            <v-avatar v-if="pendingTxs.length==0" size="24" class="">
              <v-img :src="account | identicon"></v-img>
            </v-avatar>
            <div v-if="pendingTxs.length>0" class="px-2 d-flex flex-row align-center">
              <span style="line-height: 1;">{{ pendingTxs.length }} Pending</span>
              <v-progress-circular
                :size="15"
                :width="2"
                class="ml-1"
                indeterminate
                color="white"
              ></v-progress-circular>
            </div>
          </v-btn>
        </template>
        <user-menu @close="userSheet=false" :show="userSheet" class="rounded-t-lg"></user-menu>
      </v-bottom-sheet>
      <v-menu v-else v-model="userDialog" offset-y left nudge-top="-10" 
        content-class="rounded-lg"
        :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="pendingTxs && pendingTxs.length>0" 
            text class="text-none rounded-lg px-2 ml-2 primary" v-bind="attrs" v-on="on">
            <span>{{ pendingTxs.length }} Pending</span>
            <v-progress-circular
              :size="15"
              :width="2"
              class="ml-1"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
          <v-btn v-else text class="text-none rounded-lg px-2 ml-2" v-bind="attrs" v-on="on">
            <v-avatar size="24" class="mr-1">
              <v-img :src="account | identicon"></v-img>
            </v-avatar>
            {{account | address(4)}}
            <v-icon class="ml-1">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <user-menu @close="userDialog=false" :show="userDialog" class="rounded-lg"></user-menu>
      </v-menu>
    </template>

    <v-bottom-sheet v-if="$vuetify.breakpoint.mobile" 
      v-model="settingDialog"
      overlay-opacity="0.8"
      scrollable
      content-class="bottom-sheet-content dialog-bg rounded-t-lg">
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <div class="rounded-lg card-bg d-flex flex-row align-center justify-center ml-2 pointer" 
            v-bind="attrs" v-on="on"
            v-ripple style="width: 36px;height:36px;">
            <v-icon size="24" class="icon-hover">mdi-cog-outline</v-icon>  
          </div>
        </v-hover>
      </template>
      <global-setting class="rounded-t-lg adjust-safearea" @close="settingDialog=false" :show="settingDialog"></global-setting>
    </v-bottom-sheet>
    <v-menu v-else v-model="settingDialog" offset-y left nudge-top="-10" 
      content-class="rounded-lg"
      :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <div class="rounded-lg card-bg d-flex flex-row align-center justify-center ml-2 pointer" 
            v-bind="attrs" v-on="on"
            v-ripple style="width: 36px;height:36px;">
            <v-icon size="24" class="icon-hover">mdi-cog-outline</v-icon>  
          </div>
        </v-hover>
      </template>
      <global-setting @close="settingDialog=false" :show="settingDialog"></global-setting>
    </v-menu>
    
    <connect-wallet-dialog v-model="connectDialog"></connect-wallet-dialog>
  </v-app-bar>
</template>

<script>
import ConnectCard from './ConnectCard.vue';
import ConnectWalletDialog from './ConnectWalletDialog.vue';
import NetworkCard from './NetworkCard.vue';
import UserMenu from './UserMenu.vue';
const { ethers } = require("ethers");
import utils from "@/util/utils"
import GlobalSetting from './GlobalSetting.vue';

export default {
  components:{
    ConnectWalletDialog,
    UserMenu,
    ConnectCard,
    NetworkCard,
    GlobalSetting
},
  data:()=>({
    bgColor:'#06070a',
    elevation:0,
    networkSheet: false,
    userSheet: false,
    userDialog:false,
    settingDialog: false,
    connectSheet: false,
    connectDialog: false,
    account: '',
    currentNetwork: null,
    networkError: false,
    navs:[
      {name:'header.home',to:{name:'Dashboard'}},
      {name:'header.pool',to:{name:'Pool'}},
      {name:'header.trade',to:{name:'Trade'}},
      {name:'header.earn',to:{name:'Earn'}},
      {name:'header.referral',to:{name:'Referral'}},
      // {name:'header.airdrop',to:{name:'Airdrop'}},
      {name:'header.dashboard',link:'https://dune.com/dipx/overview'},
    ],
    isWeb3Installed: true,
    pendingTxs: []
  }),
  mounted(){
    this.isWeb3Installed = utils.isMetamaskInstalled()
    this.networkError = !this.isWeb3Installed
    this.$bus.$on("walletConnect", (account) => {
      this.account = account;
      this.pendingTxs = []
    });
    this.$bus.$on("networkConnect", (network) => {
      this.pendingTxs = []
      this.currentNetwork = this.$store.getters.config.app.networks.find(item => item.chainId==network.chainId)
      if(!this.currentNetwork){
        this.networkError = true
      }else{
        this.networkError = false
      }
    });
    this.$bus.$on("PendingTx", (tx) => {
      this.pendingTxs= [
        tx,
        ...this.pendingTxs.filter(item => !utils.addressEq(tx.txhash,item.item))
      ]
    });
    this.$bus.$on("ConfirmTx", ({txhash}) => {
      this.pendingTxs = this.pendingTxs.filter(tx => !utils.addressEq(tx.txhash,txhash))
    });
    this.$bus.$on("showWalletConnectDialog", () => {
      this.showConnectDialog()
    });
    
  },
  methods:{
    onScroll(e){
      let offsetTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.bgColor = 'rgba(6, 7, 10,'+(offsetTop>50?1:offsetTop/50)+')'
      if(offsetTop>50){
        this.elevation = 4
      }else{
        this.elevation = 0
      }
    },
    showDrawer(){
      this.$bus.$emit("showDrawer")
    },
    showConnectDialog(){
      this.connectDialog = true
      this.connectSheet = false
    },
    changeNetwork(chainId){
      this.networkSheet = false
    }
  }
}
</script>

<style scoped>
.nav-active img{
  filter: brightness(0) invert(1);
}
</style>