
const { ethers } = require("ethers");
import store from '../store'

function getConfig() {
  return store.getters.config
}

function isMetamaskInstalled() {
  if (typeof window.ethereum == "undefined") {
    return false;
  }
  return true;
}

function toFixed(value,fixedNum) {
  if(!value){
    return ''
  }
  let fixedValue = ethers.FixedNumber.from(value+'').toUnsafeFloat().toFixed(fixedNum)
  return clipFloat(fixedValue)
}
function clipFloat(value){
  if(!value){
    return ''
  }
  let num = value.toString()
  let newstr = num;
  let leng = num.length - num.indexOf('.') - 1;
  if (num.indexOf('.') > -1) {
    for (let i = leng; i > 0; i--) {
      if (
        newstr.lastIndexOf('0') > -1 &&
        newstr.substr(newstr.length - 1, 1) == 0
      ) {
        let k = newstr.lastIndexOf('0');
        if (newstr.charAt(k - 1) == '.') {
          return newstr.substring(0, k - 1);
        } else {
          newstr = newstr.substring(0, k);
        }
      } else {
        return newstr;
      }
    }
  }
  return num
}
function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
function addressEq(address0,address1){
  if(!address0 || !address1){
    return false
  }
  return address0.toLowerCase() == address1.toLowerCase()
}
function isEth(token){
  const config = getConfig()
  return addressEq(token, config.chain.eth.address)
}
function isNativeCurrency(token) {
  const config = getConfig()
  if(config.chain){
    return addressEq(token, config.chain.nativeCurrency.address)
  }else{
    return false
  }
}
function isDlp(token){
  const config = getConfig()
  if(config.chain){
    return addressEq(token, config.chain.dlp.address)
  }else{
    return false
  }
}
function requireGasFee(pool){
  const config = getConfig()
  if(config.chain){
    const noRequireGasPools = config.chain.contractData.noRequireGasPools
    for (let i = 0; i < noRequireGasPools.length; i++) {
      if(addressEq(pool, noRequireGasPools[i])){
        return false
      }
    }
  }

  return true
}
function calculatePageTotal(pageSize, totalCount){
  return Math.ceil(totalCount/pageSize)
}
function calculateLiqPrice(orderType,entryPrice,size,collateral){
  
  if(orderType=="Long"){
    return ethers.BigNumber.from(entryPrice).sub(
              ethers.BigNumber.from(collateral)
                .mul(entryPrice)
                .div(size)
            )
  }else{
    return ethers.BigNumber.from(collateral)
            .mul(entryPrice)
            .div(size)
            .add(entryPrice)
  }
}

export default {
  getConfig,
  isMetamaskInstalled,
  sleep,
  isEth,
  isNativeCurrency,
  isDlp,
  addressEq,
  clipFloat,
  calculatePageTotal,
  calculateLiqPrice,
  toFixed,
  requireGasFee
}