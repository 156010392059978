<template>
  <v-dialog 
    v-model="dialog" 
    max-width="300" 
    :persistent="persistent"
    overlay-opacity="0.8"
    @input="inputChange"
    content-class="dialog-bg rounded-lg ba">
    <v-responsive :aspect-ratio="4/4">
      <v-card flat 
        color="transparent"
        class="rounded-lg py-3 px-5 d-flex flex-column justify-center align-center" 
        style="height:100%;">
        <!-- <Circle8 size="60px" class="my-4"></Circle8> -->
        <v-progress-circular
          :size="60"
          :width="2"
          class="my-4"
          indeterminate
          color="#895ff1"
        ></v-progress-circular>
        <div class="text-h6 text-center" v-if="action">{{action}}</div>
        <div class="text-body-2 text-center mt-2" v-if="msg">{{msg}}</div>
      </v-card>
    </v-responsive>
  </v-dialog>
</template>

<script>
// import {RotateSquare2,Socket,Circle8} from 'vue-loading-spinner'

export default {
  components:{
  
  },
  props: {
    value:{default: false},
    action: {default: ''},
    msg:{default: ''},
    persistent:{default:false}
  },

  data(){
    return {
      dialog: false,
    }
  },
  watch:{
    value(newVal){
      this.dialog = newVal;
    }
  },
  methods:{
    inputChange(opened){
      this.$emit('input',opened);
    }
  }

}
</script>